import api from "./HttpServiceUntoasted";

export default class UsersService {
  /**
   * Get user
   *
   * @param id
   * @returns {Promise}
   */
  getUser(id) {
    return api.get("/v1/users/" + id);
  }

  /**
   * Delete user
   *
   * @param id
   * @returns {Promise}
   */
  deleteUser(id) {
    return api.delete("/v1/users/" + id);
  }

  /**
   * Get users
   *
   * @param filters
   * @returns {Promise}
   */
  getUsers(filters = {}) {
    return api.get("/v1/users", {
      params: filters,
    });
  }

  downloadUsers(data) {
    return api.post("/v1/users/download", data);
  }

  /**
   * Get 20 users
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyUsers(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/users`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/users?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  /**
   * Set user
   *
   * @param data
   * @returns {Promise}
   */
  setUser(data) {
    return api.post("/v1/users", data);
  }

  /**
   * Update user
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateUser(id, data = {}) {
    return api.put("/v1/users/" + id, data);
  }

  /**
   * Update Logged In User
   *
   * @param {*} data
   * @returns {Promise}
   */
  updateProfile(data = {}) {
    return api.put("/v1/users", data);
  }

  /**
   * Update user email / password
   *
   * @param {*} data
   * @returns {Promise}
   */
  updateEmailPassword(data = {}) {
    return api.put("/v1/user_email_password", data);
  }

  getAPIToken(user_id) {
    return api.post("/v1/users/auth_token", {
      user_id: user_id,
    });
  }

  /**
   * Get current seller
   *
   * @returns {Promise}
   */
  getCurrentUser() {
    return api.get("/v1/current-user-group");
  }
}
