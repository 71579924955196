export default {
  components: {
    nav: {
      Overview: "Perspectiva General",
      SearchHsCode: "Buscar HS Code",
      Archives: "Archivos",
      Requests: "Solicitudes",
      NobordistCheckpoints: "Puntos de Control de Nobordist",
      ReceivedCheckpoints: "Puntos de Control Recibidos",
      StatusCheckpoints: "Status de los Puntos de Control",
      VolumeCheckpoints: "Puntos de Control del Volumen",
      TrackingNotifications: "Notificaciones del Seguimiento",
      Checkpoints: "Puntos de Control",

      Platforms: "Plataformas",

      Contracts: "Contratos",
      Deliverymethods: "Métodos de Entrega",
      PriceTablesDeliveryTime: "Tablas de Precios & Plazo de Entrega",
      ShipstationContracts: "Contratos de Shipstation",

      SellerAccounts: "Cuentas de Vendedor",
      DefaultPlans: "Planes Estándar",
      Finances: "Finanzas",

      AlertLogs: "Registros de Alerta",
      NobordistLogs: "Registros de Nobordist",
      BilLogs: "Registros de Cuentas",
      ExternalLogs: "Registros Externos",
      TrackingRules: "Reglas de Seguimiento",
      RejectedOrdersLogs: "Registros de Pedidos Rechazados",
      GeneralEmails: "Correos electrónicos generales",
      Notifications: "Notificaciones",
      DelayedJobs: "Delayed Jobs",
      Logs: "Registros",

      VolumesReverse: "Volúmenes Inversos",
      Volumes: "Volúmenes",
      Orders: "Pedidos",
      Overpacks: "Paquetes",
      Masterboxes: "Masterboxes",
      Shipments: "Remesas",
      Flights: "Vuelos",
      Shipping: "Envío",
      ShippingRules: "Reglas de envío",
      DutiesAndTaxesStatus: "Estado de derechos e impuestos",

      Users: "Usuarios",
      Carriers: "Transportadoras",
      Sellers: "Vendedores",
      Groups: "Grupos",
      Permissions: "Permisos",
      Usersmanagement: "Usuarios y vendedores",
      Settings: "Ajustes",
      Accessibility: "Accesibilidad",
      Account: "Cuenta",
      Downloads: "Downloads",
      SendNotifications: "Enviar notificaciones",
      FiscalPayments: "Pagos fiscales",
      TrackingRuleNotifications: "Reglas de notificación de seguimiento",
    },
    searchData: {
      volumes: "Volumes",
      volumesReverse: "Volumes Reverse",
      orders: "Orders",
      overpacks: "Overpacks",
      masterboxes: "Masterboxes",
      shipments: "Shipments",
      flights: "Flights",
      shippingRules: "Shipping Rules",
      dutiesAndTaxesStatus: "Duties and taxes status",
      users: "Users",
      carriers: "Carriers",
      sellers: "Sellers",
      sellerAccounts: "Seller accounts",
      defaultPlans: "Default plans",
      contracts: "Contracts",
      deliveryMethods: "Delivery Methods",
      priceTableAndDeliveryTime: "Price table and delivery time",
      shipstationContracts: "Shipstation contracts",
      alertLogs: "Alert logs",
      nobordistLogs: "Nobordist logs",
      billLogs: "Bill logs",
      externalLogs: "External logs",
      trackingRules: "Tracking rules",
      rejectedOrdersLogs: "Rejected orders logs",
      generalEmails: "General emails",
      notifications: "Notifications",
      delayedJobs: "Delayed jobs",
      searchHsCode: "Buscar HS Code",
      archives: "Archives",
      requests: "Requests",
      nobordistCheckpoints: "Nobordist checkpoints",
      receivedCheckpoints: "Received checkpoints",
      statusCheckpoints: "Status checkpoints",
      volumeCheckpoints: "Volume checkpoints",
      trackingNotifications: "Tracking notifications",
      checkpoints: "Checkpoints",
      userProfile: "My profile",
      printingSetting: "Printing settings",
      userNotifications: "User notifications",
      downloads: "Downloads",
      sendNotifications: "Enviar notificaciones",
      accessibility: "Accesibilidad",
      fiscalPayments: "Pagos fiscales",
      trackingRuleNotifications: "Reglas de notificación de seguimiento",
    },
    tableFilters: {
      title: "Filters",
      subTitle: "Select the values below to create a filter",
      addNewFilter: "Add new filter",
      clearFilters: "Clear Filters",
      filterBy: "Filter by: ",
      filteredBy: "Filtered by:",
      openFilterOptions: "Open Filter Options",
      clickToClearFilter: "Click to Clear All Filters",
      clickToFilter: "Click to Filter",
      trueText: "True",
      falseText: "False",
    },
    selectTableColumns: {
      title: "Seleccionar Columna de la Tabla",
      button: "Columnas de la Tabla",
      unSelectAll: "Desmarcar Todas las Columnas",
      selectAll: "Seleccionar Todas las Columnas",
    },
    maxRows: {
      button: "Filas",
    },
    nbTable: {
      wereFound: "se encontraron",
      all: "Todas",
      onThisPage: "en esta página",
      isSelected: "está seleccionado | seleccionados",
      worksOnlyCurrentPage:
        "Este filtro solo funciona para los {onThisPageItems} elementos mostrados en esta página de la tabla, no para los {totalItems} elementos en total. Para hacer esto, utilice el botón de filtro",
      sortBtn:
        "Ordenar los elementos en esta página de la tabla. (No ordena todos los elementos encontrados)",
      properties: "Propiedades",
    },
    itensPerPage: "Artículos Por Página",
    pagination: {
      first: "Primero",
      prev: "Anterior",
      next: "Siguiente",
      last: "Último",
    },
    topBar: {
      shiftSide: "Cambiar a la barra de navegación lateral",
      shiftTop: "Cambiar a la barra de navegación superior",
      myProfile: "Mi Perfil",
      myNotifications: "Mis Notificaciones",
      printingSetting: "Configuración de Impresión",
      platformList: "Lista de plataformas",
      savingChanges: "Guardando cambios...",
      lastChangesSaved: "Últimos cambios guardados",
    },
  },
  locales: {
    ptBR: "Portugués (BR)",
    en: "Inglés",
    cn: "Chino",
    es: "Español",
  },
  //words
  weight: "Peso",
  flow: "Flujo",
  enabled: "Habilitado",
  disabled: "Deshabilitado",
  address: "Dirección",
  body: "Cuerpo",
  any: "Qualquier",
  sent: "Enviado",
  help: "Ayuda",
  creationDate: "Fecha de creación",
  enable: "Habilitar",
  disable: "Deshabilitar",
  lastUpdate: "Última actualización",
  next: "Siguiente",
  back: "Atrás",
  metrics: "Métricas",
  filterByPeriod: "Filtrar por periodo",
  until: "Hasta",
  showMoreData: "Mostrar más datos",
  collapseData: "Contraer datos",
  generalData: "Informacion General",
  wouldYouLikeToRemoveSelectedItems:
    "¿Le gustaría eliminar los elementos seleccionados ?",
  documentsAndLabels: "Documentos y etiquetas",
  downloadReport: "Descargar",
  suggestedActions: "Acciones sugeridas",
  filename: "Nombre de archivo",
  seeDetails: "Ver detalles",
  volumes: "Volúmenes",
  clickToCopy: "Haga clic aquí para copiar",
  copy: "Copiar",
  clickToDownload: "Haga clic para descargar",
  active: "Activo",
  blocked: "Obstruido",
  connect: "Connect",
  disConnect: "Disconnect",
  connected: "Connected",
  disConnected: "Disconnected",
  contactUs: "Contact Us",
  placeholderEmail: "example@email.com",
  asynchronousRequests: "Asynchronous Requests",
  loading: "Cargando",
  login: "Iniciar Sesión",
  logout: "Salir",
  name: "Nombre",
  fullName: "Nombre completo",
  company: "Empresa",
  phoneNumber: "Número de Teléfono",
  whatsNumber: "Número de Whatsapp",
  phone: "Teléfono",
  cellphone: "Teléfono móvil",
  optional: "Opcional",
  email: "Correo electrónico",
  platform: "Plataforma",
  select: "Selecciona",
  selected: "Seleccionado",
  notselected: "No Seleccionado",
  selectAll: "Seleccionar Todos",
  clearSelected: "Limpiar Seleccionados",
  selectColumns: "Seleccionar Columnas",
  clearFilter: "Limpiar Filtros",
  filters: "Filtros",
  filter: "Filtro",
  all: "Todos",
  anyCounties: "Cualquier país",
  close: "Cerrar",
  save: "Guardar",
  cancel: "Cancelar",
  actions: "Acciones",
  add: "Agregar",
  delete: "Borrar",
  selectAnOption: "Seleccionar una Opción",
  start: "Começo",
  end: "Fin",
  yes: "Sí",
  no: "No",
  ok: "Ok",
  attention: "Atención",
  found: "encontrado",
  support: "Soporte",
  edit: "editar",
  password: "Contraseña",
  hidePass: "Esconder Contraseña",
  showPass: "Mostrar Contraseña",
  changePass: "Cambiar Contraseña",
  remove: "Eliminar",
  search: "Buscar",
  upload: "Cargar",
  download: "Descargar",
  beginningDate: "Fecha de inicio",
  startDate: "Fecha de inicio",
  endDate: "Fecha Final",
  between: "Entre",
  betweenDates: "Entre Fechas",
  registrationDate: "Fecha de Registro",
  and: "Y",
  selectOption: "Select an option",
  paymentDate: "Data de Pago",
  new: "Nuevo",
  allowed: "Permitido",
  invalid: "Inválido",
  errorMessages: {
    isNotPDF: "File is not .pdf",
    tryAgain: "Please, check you information and try again",
    required: "Can't be blank",
    invalidCPF: "Invalid CPF",
    invalidCNPJ: "Invalid CNPJ",
    invalidDocument: "Invalid document",
    invalidZIPCode: "Invalid zip code",
    invalidPhone: "Invalid phone",
    invalidEmail: "Invalid E-mail",
    lowerMoney: "must be greater than 0.01",
    genericError: "Something unexcepted happened.",
    whiteSpace: "should not contain white space",
    minLength: "at least %{value} characters",
    minLowercase: "at least %{value} lowercase letter",
    minUppercase: "at least %{value} uppercase letter",
    minNumber: "at least %{value} number",
    minSpecialCharacter: "at least %{value} special character",
    betweenDigits: "%{min} u %{max} dígitos",
  },
  //pages
  homePage: {
    greeting: `Hola, {name}`,
    subtitle: "¿Qué te gustaría hacer hoy?",
    links: {
      orders: {
        title: "Ver pedidos",
        description: "Consulta pedidos y descarga informes.",
        action: "Ir a pedidos",
      },
      sellers: {
        title: "Gestión de vendedores",
        description: "Compruebe los usuarios y vendedores.",
        action: "Ir a vendedores",
      },

      masterboxes: {
        title: "Ver Masterboxes",
        description: "Consulta las masterboxes.",
        action: "Ir a masterboxes",
      },
      flights: {
        title: "Gestionar Vuelos",
        description:
          "Consulta e informa en qué vuelo se encuentra cada pedido.",
        action: "Ir a vuelos",
      },
      contracts: {
        title: "Gestionar Contratos",
        description: "Accede para gestionar contratos.",
        action: "Ir a contratos",
      },
    },
  },
  loginPage: {
    forgotPassword: "Olvidaste tu contraseña",
    resetPassword:
      "Se ha enviado un correo electrónico de restablecimiento de contraseña",
    checkInbox: "Revisa tu bandeja de entrada",
    sendReset: "Envíame instrucciones para restablecer la contraseña",
    login: "Login",
    welcome: "Welcome",
    pleaseYourAcc: "Please enter your account",
    wantoToApp: "Want to access management system",
    goToApp: "Want to access management system",
    checkOur: "Check out our",
    generalConditionsService: "Conditions of Service",
    resetPasswordSent: "Reset password email has sent! Check your inbox.",
    sendResetPasswordInstructions: "Send me reset password instructions",
    iAgree: "I agree",
    agreementText:
      "By cheking this box, you acknowledge that you have read and agree to our General Conditions of Service to be bound by this Agreement.",
    createYourAccount: "Create your account",
    fillInfoToCreateAcc: "Fill in the following fields to create your account",
    userData: "User data",
    pleaseYourData: "Please fill your data",
    companyData: "Company data",
    pleaseCompanyData: "Please fill company data",
    hasRegistration: "Already registered",
    accessMyAcc: "Access my account",
    needHelp: "Need help",
    talkWithOur: "Talk to one of our consultants",
    ifCheckOut: "If you prefer, check out our",
  },
  pintingSettingsPage: {
    title:
      "Establece la configuración predeterminada para las opciones de impresión de etiquetas aquí",
    cN23Label: "Etiqueta CN23",
    commercialInvoice: "Factura Comercial",
    packingSlip: "Guía de Remesa",
    cevaBatteryShipperConfirmation: "Ceva Battery Shipper Confirmation",
  },
  userProfileEditPage: {
    title: "Actualizar mi perfil",
  },
  userProfilePage: {
    title: "Mi cuenta",
    subtitle:
      "Consultar y actualizar, si es necesario, su información personal.",
    userData: "datos del usuario",
    loginInformation: "Información Entrar",
    loginInformationSubtitle:
      "Si es necesario, actualice los datos que utiliza para acceder a la plataforma.",
    checkYourData: "Verifique y edite sus datos, si es necesario",
    newPass: "Nueva Contraseña",
    currentPass: "Contraseña Actual",
    newPassConfirm: "Ingrese una nueva contraseña para cambiar",
    editEmail: "Editar Correo electrónico",
    editProfile: "Editar Perfil",
    newEmail: "Nuevo Correo electrónico",
    checkEmail: "Revise el formato de su Correo electrónico",
    sameEmail:
      "Los dos correos electrónicos ingresados ​​son diferentes, revísalos",
    confirmCurrentPassword:
      "Para realizar esta acción, escriba su contraseña actual",
  },
  overViewPage: {
    title: "Perspectiva General",
    getInfoCorreios: "Obtenga información del Correo",
    putTrackingNumber: "Ingrese el Número de Seguimiento",
    putDispatchNumberUnitCode:
      "Ingrese el Número de Despacho o Código de la Unidad",
    manuallyUpdateCN38Number: "Actualiza manualmente el número CN38",
    shipmentID: "ID de la Remesa",
    newCN38Number: "Nuevo Número CN38",
    updateCN38: "Actualizar CN38",
    resetDispatchNumbersCounter: "Restablecer contador de números de despacho",
    newCounterStart: "Nuevo inicio de contador",
    reset: "Reiniciar",
    removeOverpack: "Eliminar Paquetes",
    testDelayedJob: "Probar Delayed Job",
    test: "Prueba",
    generateRequestID: "Generar ID de Solicitud",
    updateCn38Number: "Actualizar número CN38",
    sureOverwrite: "Vas a reemplazar el número CN38 de la Remesa",
    definitiveAction: "Esta acción es definitiva. Estás dispuesto a continuar",
    sureResetCorreiosCounter:
      "Vas a restablecer el contador de Correos para los números de despacho",
    sureRemoveOverpack: "Deseas eliminar este paquete",
  },
  downloadsPage: {
    title: "Archivos",
    subtitle: "Todos los descargas están centralizados en esta página",
    errors: "Errores",
    found: "ninguna descarga encontrada | descarga | descargas",
    downloadReport: "Descargar",
    showErrors: "Mostrar errores",
    status: {
      failed: "fallido",
      processing: "procesando",
      done: "hecho",
      starting_upload: "iniciando carga",
      checking_csv_format: "verificando formato CSV",
      checking_columns: "verificando columnas",
      checking_equal_orders_volumes: "verificando volúmenes de órdenes iguales",
      checking_cells: "verificando celdas",
      validation_success:
        "¡validaciones finalizadas con éxito! creando la tabla",
    },
    fields: {
      id: "ID",
      type: "Tipo",
      status: "Estado",
      fileName: "Nombre de archivo",
      creationDate: "Fecha de creación",
      messages: "Mensajes",
    },
  },
  nobordistCheckpointsPage: {
    title: "Puntos de Control de Nobordist",
    description:
      "Esta es la lista completa de los estados de seguimiento de Nobordist que compondrán el historial de seguimiento de los pedidos",
    downloadCheckpointsCSV: "Descargar Puntos de Control en CSV",
    downloadCheckpointsExcel: "Descargar Puntos de Control en Excel",
    checkDownloadsAt:
      "Revisa las descargas de procesamiento en... página de descargas",
    fields: {
      type: "Tipo",
      slug: "Slug",
      name: "Nombre",
      title: "Título",
      trackingCode: "Código de Seguimiento",
      namePT: "Nombre PT",
      titlePT: "Título PT",
      finalStatus: "Status Final",
      category: "Categoría",
      description: "Descripción",
    },
  },
  nobordistCheckpointsViewPage: {
    title: "Puntos de Control de Nobordist #",
    type: "Tipo",
    slug: "Slug",
    titleField: "Título",
    titlePt: "Título en Pt",
    description: "descripción",
    descriptionPt: "Descripción en Pt",
    name: "Nombre",
    trackingCode: "Código de Seguimiento",
  },
  receivedCheckpointsPage: {
    title: "Puntos de Control Recibidos",
    description:
      "La lista de todos los estados de seguimiento que capturamos de nuestros socios de transporte. Algunos de ellos pueden ser ignorados en el historial de seguimiento del pedido porque son redundantes o irrelevantes. Si desea ver lo que se agregó al historial de seguimiento, haga clic en la sección de Puntos de Control de Volumen",
    fields: {
      trackingNumber: "Número de Seguimiento",
      trackingFunction: "Función de Seguimiento",
      statusCode: "Código de Status",
      message: "Mensaje",
      dateIso: "Fecha Iso",
      creationDate: "Fecha de Creación",
    },
  },
  receivedCheckpointsViewPage: {
    title: "Punto de Control Recibido #",
    trackingNumber: "Número de Seguimiento",
    trackingFunction: "Función de Seguimiento",
    statusCode: "Código de Status",
    dateIso: "Fecha Iso",
    date: "Fecha",
    message: "Mensaje",
    fields: {
      receiverType: "Tipo de Destinatario",
      notificationID: "Nb Notificación ID",
      volumeID: "Volumen ID",
      creationDate: "Fecha de Creación",
    },
  },
  sendNotificationsViewPage: {
    title: "Enviar Servicio #",
    receivedNotification: "Notificación Recibida",
    //fields
    receiverType: "Tipo de Destinatario",
    email: "Correo electrónico",
    webhook: "Webhook",
    phone: "Teléfono",
    notificationID: "Nb Notificación ID",
    volumeID: "Volumen ID",
    sellerName: "Nombre del Vendedor",
    trackingCode: "Código de Seguimiento",
    titleField: "Título",
    createdDate: "Fecha de Creación",
    time: "Horario",
    receivedCheckpointID: "ID del Punto de Control Recibido",
    trackingNumber: "Número de Seguimiento",
    trackingFunction: "Función de Seguimiento",
    statusCode: "Código de Status",
    dateIso: "Fecha Iso",
  },
  statusCheckpointsPage: {
    title: "Status de los Puntos de Control",
    description:
      "La traducción de los estados de seguimiento de nuestros socios de transporte a nuestros propios estados de seguimiento de Nobordist",
    addStatusCheckpoint: "Agregar Status de Puntos de Control",
    addNewStatusCheckpoint: "Agregar Nuevo Status de Puntos de Control",
    uploadStatusCheckpoint: "Cargar Status de Puntos de Control",
    checkpointID: "ID del Punto de Control",
    fields: {
      statusCode: "Código de Status",
      trackingFunction: "Función de Seguimiento",
      checkpoint: "Punto de Control",
      creationDate: "Fecha de Creación",
      edit: "Editar",
    },
    notApplied: "Não aplicado",
  },
  trackingNotificationsPage: {
    title: "Notificaciones de Seguimiento",
    description:
      "La lista de todas las notificaciones (correos electrónicos, sms, webhooks, ...) enviadas sobre actualizaciones de seguimiento",
    fields: {
      notificationType: "Tipo de Notificación",
      volumeCheckpointID: "ID del Punto de Control del Volumen",
      data: "Destinatario",
      sent: "Enviado",
    },
  },
  volumeCheckpointsPage: {
    title: "Puntos de Control del Volumen",
    description:
      "La lista de todos los estados de seguimiento que agregamos al historial de seguimiento de los pedidos",
    generateVolumeLabel: "Generar etiqueta de volumen",
    fields: {
      volumeID: "Volumen ID",
      receivedCheckpointID: "ID del Punto de Control Recibido",
      convertedNotifs: "Notificaciones Convertidas",
      checkpoint: "Punto de Control",
      DateISO: "Fecha ISO",
      message: "Mensaje",
      notificationType: "Tipo de notificación",
      recipient: "Destinatario",
      trackingNotification: "Notificación de seguimiento",
    },
  },
  volumeCheckpointsViewPage: {
    title: "Punto de Control del Volumen #",
    checkpoint: "Punto de Control",
    message: "Mensaje",
    attachment: "Adjunto",
    volumeID: "Volumen ID",
    receivedCheckpointID: "ID del Punto de Control Recibido",
    ConvertedNotifs: "Notificaciones Convertidas",
    checkpointID: "ID del Punto de Control",
    created: "Creación",
    dateIso: "Fecha Iso",
    type: "Tipo",
    slug: "Slug",
    name: "Nombre",
    description: "Descripción",
    trackingCode: "Código de Seguimiento",
    titleField: "Título",
    namePT: "Nombre PT",
    descriptionPT: "Descripción en PT",
    titlePT: "Título en PT",
    hierarchyStatus: "Status Jerárquico",
    height: "Alto",
    width: "Ancho",
    length: "Largo",
    weight: "Peso",
    cubageFactor: "Factor de Cubicación",
    volumeType: "Tipo de Volumen",
    orderID: "Pedido ID",
    lastMileTrackingNumber: "Número de seguimiento de la primera milla",
    orderReference: "Referencia del Pedido",
    lastMileCarrierNumber: "Número de la transportadora de la última milla",
    fields: {
      name: "Nombre",
      description: "Descripción",
      sku: "Sku",
      valueWithTax: "Valor con Impuesto",
      value: "Valor",
      hsCode: "Hs Code",
      quantity: "Cantidad",
      originCountry: "País de Origen",
      length: "Largo",
      width: "Ancho",
      height: "Alto",
      weight: "Peso",
    },
  },
  contractsPage: {
    title: "Contratos",
    importContract: "Importar Contrato desde JSON",
    addContracts: "Agregar Contratos",
    updateValidityDate: "Actualizar Fecha de Validez",
    showAdditionalServices: "Mostrar Servicios Adicionales",
    addNewContract: "Agregar Nuevo Contrato",
    name: "Nombre",
    slug: "Slug",
    incoterm: "Términos Internacionales de Comercio",
    taxingType: "Tipo Impositivo",
    maximumWeight: "Peso Máximo",
    cubageFactor: "Factor de Cubicación",
    maximumLength: "Largo Máximo",
    maximumHeight: "Altura Máxima",
    maximumWidth: "Ancho Máximo",
    maximumPriceLimit: "Límite Máximo de Precio",
    additionalServices: "Servicios Adicionales",
    externalID: "ID Externo:",
    hSCodes: "HS Codes:",
    triggerOption: "Opción de Activación:",
    triggerCondition: "Condición de Activación:",
    primaryType: "Tipo Primario:",
    primaryValue: "Valor Primario:",
    primaryVariable: "Variable Primaria",
    secondaryVariable: "Variable Secundaria:",
    secondaryType: "Tipo Secundario:",
    secondaryValue: "Valor Secundario:",
    selecttype: "Seleccione el tipo",
    addToServices: "Agregar a los Servicios",
    cancelNewService: "Cancelar Nuevo Servicio",
    newService: "Nuevo Servicio",
    botNotifications: "Dutypay Notifications",
    isOldFlow: "Es Flujo Antiguo",
    airporttoDoor: "Aeropuerto a Puerta",
    automatizePayment: "Automatizar Pago",
    needSupervisorApproval:
      "Esta acción necesita de la aprobación de un supervisor",
    chooseSendTo: "Por favor, elige a quién deseas enviarle la solicitud",
    firstMileDM: "Primera Milla DM",
    freightForwarderDM: "Despachante DM",
    customClearenceDM: "Despacho Aduanero DM",
    lastMileDM: "Última Milla DM",
    priceTable: "Tabla de Precios",
    reverseDM: "Inversa DM",
    deliveryTime: "Plazo de Entrega",
    destinationCountry: "País de destino",
    originCountry: "País de origen",
    fields: {
      name: "Nombre",
      slug: "Slug",
      firstMileDeliveryMethod: "Método de Entrega de la Primera Milla",
      freightForwarderDeliveryMethod: "Método de Entrega del Despachante",
      customClearenceDeliveryMethod: "Método de Entrega de Despacho Aduanero",
      lastMileDeliveryMethod: "Método de Entrega de la Última Milla",
      reverseDeliveryMethod: "Método de Entrega de Inversa",
      priceTable: "Tabla de Precios",
      deliveryTime: "Plazo de Entrega",
      priceLmit: "Precio Límite",
      creationDate: "Fecha de Creación",
      taxingType: "Tipo Impositivo",
      incoterm: "Términos Internacionales de Comercio",
      automaticPayment: "Pago Automático",
      maximumWeight: "Peso Máximo",
      maximumWidth: "Ancho Máximo",
      maximumHeight: "Altura Máxima",
      maximumLength: "Largo Máximo",
      cubageFactor: "Factor de Cubicación",
      additionalServices: "Servicios Adicionales",
      isMadeBySeller: "Hecho Por El Vendedor",
    },
    nameHelpText: "Así es como el vendedor verá el contrato",
    slugHelpText: "Esto es para uso interno, el vendedor no verá este nombre",
    incotermHelpText: "Qué incoterms se permitirán con este contrato",
    beginAndDateHelpText:
      "Esto establece la fecha de validez del contrato. Fuera de este período, el vendedor no podrá usarlo",
    flowHelpText:
      "El flujo ANTIGUO delega al vendedor la responsabilidad de crear overpacks. En el flujo NUEVO, el administrador lo hará a través de masterboxes. Recomendado: NUEVO",
    modeHelpText:
      "Aeropuerto a Puerta indica que el contrato solo se encargará de la Aduana y Última Milla, mientras que Puerta a Puerta indica que el contrato está a cargo de todo el proceso comenzando en el país de origen",
    importContractFromJsonHelpText:
      "Esto le permite recrear un contrato de otro entorno. Ejemplo: ha creado un contrato en el entorno de homologación y le gustaría crear el mismo contrato exacto en el entorno de producción. En ese caso, solo necesita hacer clic en “Descargar Configuraciones del Contrato” en la página del contrato en el entorno de homologación y cargar el archivo json descargado aquí",
    downloadContractsSettingsHelpText:
      "Esto generará un documento json que puede usar para copiar este contrato a otro entorno",
    ifOrderReceives:
      "Si el pedido recibe uno de los siguientes puntos de control",
    sellerWillBeBilled: "Se facturará al vendedor un costo adicional llamado",
    andTheItemHasOneOfHscodes:
      "Y el artículo tiene uno de los siguientes códigos HS",

    valueBilledFlat: "El valor facturado será de {value} por {type}",
    valueBilledFlatType: "@:contractsPage.valueBilledFlat y por {type2}",

    andValueBilledFlat: " Y {value} por {type}",
    andValueBilledFlatType: " Y {value} por {type1} y por {type2}",

    valueBilledPercent:
      "El valor facturado será de {value} del valor del {type}",
    valueBilledPercentType1:
      "@:contractsPage.valueBilledPercent, por cada {type2}",
    valueBilledPercentType2:
      "@:contractsPage.valueBilledPercent, por cada {type2} y cada {type3}",

    andValueBilledPercent: " Y {value} del valor del {type}",
    andValueBilledPercentType1:
      " Y {value} del valor del {type1}, por cada {type2}",
    andValueBilledPercentType2:
      " Y {value} del valor del {type1}, por cada {type2} y cada {type3}",

    day: "día",
    volume: "volumen",
    item: "artículo | artículos",
  },
  contractsViewPage: {
    title: "Contrato #",
    downloadContractSettings: "Descargar Configuraciones de Contrato",
    name: "Nombre",
    ID: "ID",
    slug: "Slug",
    created: "Creación",
    firstMileDeliveryMethod: "Método de Entrega de Primera Milla",
    freightForwarderDeliveryMethod: "Método de Entrega del Despachante",
    customClearenceDeliveryMethod: "Método de Entrega de Despacho Aduanero",
    lastMileDeliveryMethod: "Método de Entrega de la Última Milla",
    isOldFlow: "Es Flujo Antiguo",
    priceTable: "Tabla de Precios",
    obsolete: "Obsoleto",
    generatePaymentLink: "Generar Link de Pago",
    taxingType: "Tipo Impositivo",
    incoterm: "Términos Internacionales de Comercio",
    automatizedpayment: "Pago Automatizado",
    reverseDeliveryMethod: "Método de Entrega de Inversa",
    deliveryTime: "Plazo de Entrega",
    airporttoDoor: "Aeropuerto a Puerta",
    doorToDoor: "Puerta a Puerta",
    maximumWeight: "Peso Máximo",
    cubageFactor: "Factor de Cubicación",
    maximumPrice: "Precio Máximo",
    maximumLength: "Largo Máximo",
    maximumHeight: "Altura Máxima",
    maximumWidth: "Ancho Máximo",
    externalID: "ID Externo",
    triggerOption: "Opción de Activación",
    triggerCondition: "Condición de Activación",
    primaryVariable: "Variable Primaria",
    primaryValue: "Valor Primario",
    secondaryVariable: "Variable Secundaria",
    secondaryValue: "Valor Secundario",
    additionalServices: "Servicios Adicionales",
    hSCodes: "HS Codes",
    editContract: "Editar Contrato",
    cloneContract: "Clonar Contrato",
    slugForNewContract: "Slug Para el Nuevo Contrato",
    isObsolete: "Es Obsoleto",
    primaryType: "Tipo Primario",
    secondaryType: "Tipo Secundario",
    selecttype: "Selecciona el tipo",
    addServices: "Agregar a los Servicios",
    cancelNewService: "Cancelar Nuevo Servicio",
    newService: "Nuevo Servicio",
    automatizedPayment: "Pago Automatizado",
    airportToDoor: "Aeropuerto a Puerta",
    needsSupervisorApproval:
      "Esta acción necesita de la aprobación de un supervisor",
    chooseWhoSendRequest:
      "Por favor, elige a quién deseas enviarle la solicitud",
    wantToMakeContractObsolete: "Quieres dejar obsoleto este contrato",
    notBePossibleVisualize: "No será posible verlo ni realizar cambios.",
    removeService: "Eliminar Servicio",
    firstMileDM: "Primera Milla DM",
    freightForwarderDM: "Despachante DM",
    customClearenceDM: "Despacho Aduanero DM",
    lastMileDM: "Última Milla DM",
    reverseDM: "Reversa DM",
    slugHelpText:
      "Nombre interno para uso del administrador, no aparecerá al vendedor",
    configurations: "Configuraciones",
  },
  deliveryMethodsPage: {
    title: "Métodos de Entrega",
    addDeliveryMethod: "Agregar Métodos de Entrega",
    cooperations: "Cooperaciones",
    addCooperations: "Agregar otra cooperación",
    eachCooperationUniqueTitle: "Cada cooperación tiene un título único",
    name: "Nombre",
    service: "Servicio",
    selectGroup: "Selecciona el grupo",
    type: "Tipo",
    nationalization: "Nacionalización",
    trackingCredentials: "Credenciales de Seguimiento",
    addTracking: "Agregar Seguimiento",
    dispatchCredentials: "Despachar Credenciales",
    addDispatch: "Agregar Despacho",
    key: "Llave de autenticación",
    value: "Valor",
    carrier: "Transportadora",
    cooperationSlug: "Slug de la Cooperación",
    fields: {
      name: "Nombre",
      service: "Servicio",
      type: "Tipo",
      carrier: "Transportadora",
      carrierID: "ID de la Transportadora",
      creationDate: "Fecha de Creación",
      cooperationSlug: "Slug de la Cooperación",
      createdBySeller: "Creado por el Vendedor",
    },
  },
  deliveryMethodsViewPage: {
    title: "Método de Entrega #",
    usedContracts: "Contratos que utilizan este método de entrega",
    editDeliveryMethod: "Editar, Método de Entrega",
    edit: "Editar",
    name: "Nombre",
    service: "Servicio",
    selectgroup: "Selecciona el grupo",
    type: "Tipo",
    carrier: "Transportadora",
    selectcarrier: "Selecciona la Transportadora",
    nationalization: "Nacionalización",
    trackingCredentials: "Credenciales de Seguimiento",
    key: "Llave de autenticación",
    value: "Valor",
    addTracking: "Agregar Seguimiento",
    dispatchCredentials: "Despachar Credenciales",
    addDispatch: "Agregar Despacho",
    owner: "Propietario",
    ownerReference: "Referencia del Propietario",
    cooperationSlug: "Slug de la Cooperación",
  },
  priceTablesPage: {
    titlePrice: "Tabla de Precios",
    titleDelivery: "Plazos de Entrega",
    importPricesTable: "Importar Tabla de Precios",
    importDeliveryTime: "Importar Plazo de Entrega",
    suretodelete: "Estás seguro de que deseas borrar",
    selectCsv: "Selecciona un archivo csv para cargar",
    fileUploaded: "Archivo enviado",
    downloadHere: "Descarga Aquí",
    ablankTable: "una tabla vacía",
    import: "Importar",
    name: "Nombre",
    removeOrders: "Eliminar pedidos",
    shouldRemove1: "¿Realmente quieres eliminar estos",
    shouldRemove2: " pedidos? Esta acción es irreversible.",
    shouldRemove3: " Esta acción es irreversible.",
    alreadyInMasterbox:
      "Los pedidos seleccionados ya están vinculados a una masterbox y no se eliminarán",
    someInMasterbox:
      "Algunos pedidos ya están vinculados a una masterbox y no se eliminarán.",
    filesUpload: "Explora tus archivos y cárgalos",
    needsSupervisorApproval:
      "Esta acción necesita de la aprobación de un supervisor",
    chooseToSendRequest:
      "Por favor, elige a quién deseas enviarle la solicitud",
    fields: {
      name: "Nombre",
      actions: "Acciones",
    },
    priceTableDescription:
      "Con la tabla de precios, puedes establecer el costo de envío para cada código postal y peso",
    deliveryTimeTableDescription:
      "Con la tabla de tiempos de entrega, puedes establecer el tiempo de entrega para cada código postal",
    nameTooltip:
      "Dale un nombre para identificar tu archivo. Tus vendedores no lo verán.",
    dropDownHelp:
      'Primero, haz clic en el botón "Descargar tabla en blanco aquí". Descargarás un archivo CSV con datos vacíos. Luego, rellénalo y haz clic en "Subir" para iniciar el proceso de importación.',
  },
  shipstationContractsPage: {
    title: "Contratos de Shipstation",
    addShipstationContract: "Agregar Contratos de Shipstation",
    shipstationContract: "Contratos de Shipstation",
    shipstationContractName: "Nombre de los Contratos de Shipstation",
    seller: "Vendedor",
    contract: "Contrato",
    //
    fields: {
      contract: "Contratos",
      sellerID: "Vendedor ID",
      shipstationContractName: "Nombre de los Contratos de Shipstation",
      actions: "Acciones",
    },
  },
  defaultPlansPage: {
    title: "Planes de Cuenta de Vendedor",
    name: "Nombre",
    selectMultipleSellers: "Selecciona Varios Vendedores",
    email: "Correo electrónico",
    applySellers: "Aplicar a los Vendedores",
    newPlan: "Nuevo Plan",
    components: {
      categoryForm: {
        chargeDay: "Día de Cargo",
        firstChargeDay: "Primer día de Cargo",
        chargeFrequency: "Frecuencia de cargo",
        limit: "Límite",
        fixedChargeDay: "Día de Cargo Fijo",
      },
      groupCategoryCards: {
        cost: "Costo",
        trackingStatus: "Status de Seguimiento",
        sMSCost: "Costo de SMS",
        whatsappCost: "Costo de Whatsapp",
      },
    },
  },
  sellerAccountEventsPage: {
    title: "Eventos de la cuenta del vendedor",
    addCredit: "Agregar Crédito",
    filterCategory: "Filtrar por categoría",
    limit: "Límite",
    balanceIs: "El saldo es",
    nBxBelowLimit: "NBx por debajo del límite",
    noLimit: "Sin límite",
    seller: "Vendedor",
    amount: "Cantidad",
    currentExchange: "Cambio Actual",
    description: "Descripción",
    defaultPlans: "Planes estándar",
    choosePlan: "Escoge un plan",
    generalSettings: "Configuraciones Generales",
    subscriptionFee: "Tasa de inscripción",
    nBXvalueUSD: "Valor NBX en USD",
    limitTax: "Límite Impositivo",
    notifications: "Notificaciones",
    whatsappCost: "Costo de Whatsapp",
    sMSCost: "Costo de SMS",
    freight: "Flete",
    freightCost: "Costo del Flete",
    settings: "Configuraciones",
    chargeDay: "Día de Cargo",
    firstChargeDay: "Primer Día de Cargo",
    chargeFrequency: "Frecuencia de Cargo",
    fixedChargeDay: "Día de Cargo Fijo",
    trackingCode: "Código de Seguimiento",
    updateSettings: "Editar Configuraciones",
    fields: {
      sellerID: "ID del Vendedor",
      sellerName: "Nombre del Vendedor",
      category: "Categoría",
      attachment: "Adjunto",
      value: "Valor",
      exchange: "Cambio",
      description: "Descripción",
      creationDate: "Fecha de Creación",
      startValue: "Valor Inicial",
      endValue: "Valor Final",
    },
    currentExchangeHelpText: "Tipo de cambio actual: $1 es igual a R$ %{value}",
    howMuchDoYouWantAdd: "¿Cuánto quieres agregar?",
    creditOperation: "Haga clic para cambiar a una operación de CRÉDITO",
    debitOperation: "Haga clic para cambiar a una operación de DÉBITO",
  },
  alertLogsPage: {
    title: "Gestión de Registros",
    importValuesandStatuses: "Importar Valores y Status",
    updateStatus: "Actualizar Status",
    downloadLogs: "Descargar Registros",
    updateStatusInfo:
      "Si sólo desea actualizar los estados, deje vacía la columna 'valor_impuesto' (se debe mantener el nombre de la columna)",
    new: "Nuevo",
    solved: "Resuelto",
    priority: "Prioridad",
    inProgress: "En Progreso",
    waitingforPayment: "Esperando el Pago",
    sentAgain: "Enviado Nuevamente",
    paymentDone: "Pago Efectuado",
    pending: "Pendiente",
    paidExternally: "Pago Externamente",
    needSellerAction: "Necesita de Acción del Vendedor",
    status: "Status",
    comment: "Comentario",
    checktheprocessingdownloads:
      "Revisa las descargas de procesamiento en... página de descargas",
    sureWantSave: "Estás seguro de que deseas guardar los cambios",
    newTrackingNumbers: "Nuevo número de seguimiento",
    shouldbeCommaSeparated:
      "Los nuevos números deben separarse por coma y seguir la secuencia de Tracking Alert Logs seleccionados: TAL_IDs:",
    unsaveddata: "Datos no Guardados",
    pleasesaveunsaveddata: "Guarda los datos no guardados o cancele",
    okay: "OK",
    name: "Nombre",
    browseFilesUpload: "Explora tus archivos y carga tus archivos",
    selectcsvFileUpload: "Selecciona un archivo csv para cargar",
    downloadHere: "Descarga Aquí",
    instructionsXLSfile: "El archivo XLS de instrucciones",
    fileUploaded: "Archivo enviado",
    blankTable: "un archivo en blanco",
    updateAllSelectedLogs: "Actualizar todos los registros seleccionados",
    barcode: "Código de Barras",
    importPaymentCertificates: "Importar certificados de pago",
    importPdfPaymentCertificatesHelp:
      'Importar el PDF de los certificados de pago combinados: el sistema los separará y los pondrá a disposición en la columna "Certificado de Pago"',
    fields: {
      volumeId: "Volumen Id",
      trackingCode: "Código de Seguimiento",
      sellerId: "Id del Vendedor",
      seller: "Vendedor",
      message: "Mensaje",
      attachment: "Adjunto",
      lastMileTrackingNumber: "Número de seguimiento de la última milla",
      dateISO: "Fecha ISO",
      creationTime: "Hora de Creación",
      title: "Título",
      rule: "Regla",
      type: "Tipo",
      status: "Status",
      comment: "Comentario",
      dutiesPayment: "Pago de Impuestos",
      volume: "Volumen",
    },
  },
  alertLogsViewPage: {
    title: "Registro de Alerta #",
    sellerChargedAmount: "Valor cobrado por el vendedor",
    exchange: "Cambio",
    incoterm: "Términos Internacionales de Comercio",
    status: "Status",
    date: "Fecha",
    dateIso: "Fecha Iso",
    orderID: "Pedido ID",
    orderNumber: "Número del Pedido",
    volumeID: "Volumen ID",
    sellerID: "Vendedor ID",
    taxID: "Número de Identificación Fiscal",
    lastMileNumber: "Número de la última milla",
    productsValue: "Valor de los Productos",
    freightValue: "Valor del flete",
    expectedTax: "Impuesto esperado",
    realTax: "Impuesto Real",
    valueR$: "Valor R$",
    downloadAttachment: "Descargar archivo adjunto",
    downloadPaymentCertificate: "Descargar Certificado de Pago",
  },
  bilLogsPage: {
    title: "Registros de Cuentas",
    fields: {
      mawb: "MAWB",
      house: "House",
      volumeID: "Volumen ID",
      status: "Status",
      creationDate: "Fecha de Creación",
    },
  },
  bilLogsViewPage: {
    title: "Registro de Cargo#",
    mawb: "Mawb",
    house: "House",
    creationDate: "Fecha de Creación",
    status: "Status",
    volumeID: "Volumen ID",
    logs: "Registros",
  },
  delayedJobsPage: {
    title: "Registros Delayed Jobs",
    fields: {
      queue: "Fila",
      priority: "Prioridad",
      attempts: "Intentos",
      handler: "Gestión",
      lastError: "Último Error",
      runat: "Ejecutado en",
      lockedat: "Bloqueado en",
      failedat: "Falla en ",
      lockedby: "Bloqueado por",
    },
  },
  externalLogs: {
    title: "Registros externos de APIs",
    fields: {
      apiName: "Nombre API",
      function: "Función",
      userID: "Usuario ID",
      response: "Respuesta",
      creationDate: "Fecha de Creación",
    },
  },
  externalViewLogs: {
    title: "Registro API Externo #",
    apiName: "Nombre API",
    userName: "Nombre del Usuario",
    function: "Función",
    creationDate: "Fecha de Creación",
    updateDate: "Fecha de Actualización",
    params: "Parámetros",
    response: "Respuesta",
  },
  generalEmailsPage: {
    title: "Correos electrónicos Generales",
    subTitle:
      "En esta página, puedes enviar correos electrónicos a quien quieras, usando la dirección de remitente configurada",
    createGeneralEmails: "Crear Correo electrónico General",
    titleGeneral: "Título",
    sellersSelected: "Seleccionar Vendedores",
    body: "Cuerpo del Correo electrónico",
    destination: "Destinatario",
    destinationsSellers: "Destinatarios de los Vendedores",
    fields: {
      titleGeneral: "Título",
      Destination: "Destinatario",
      Body: "Cuerpo del Correo electrónico",
      Sent: "Enviado",
    },
    emailTo: "Enviar correo electrónico a {email}",
    chooseATitle: "Elige un título para tu correo electrónico",
    chooseTheRecipient: "¿Cómo deseas elegir al destinatario?",
    typeEmailAddress: "Escribe la dirección de correo electrónico",
    chooseAmongMySellers: "Elige entre mis vendedores",
    clickHereToChangeThisAddress: "Haz clic aquí para cambiar esta dirección",
    changeYourConfiguratedSenderAddress:
      "Cambia tu dirección de remitente configurada",
    youDontHaveAnyAddressSetUpYet: "Aún no has configurado ninguna dirección",
  },
  gGeneralEmailsViewPage: {
    title: "Correos electrónicos Generales #",
    iD: "ID",
    sent: "Enviado",
    titleGeneral: "Título",
    destination: "Destinatario",
    body: "Cuerpo del Correo electrónico",
    deleteGeneralEmail: "Borrar Correos electrónicos Generales",
    sureWantDelete:
      "Estás seguro de que deseas borrar este Correo electrónico General",
  },
  nobordistLogsPage: {
    title: "Registros Nobordist",
    fields: {
      action: "Acción",
      actionType: "Tipo de Acción",
      userID: "Usuario ID",
      logs: "Registros",
      creationDate: "Fecha de Creación",
    },
  },
  nobordistLogsViewPage: {
    title: "Registro #",
    action: "Acción",
    userName: "Nombre de Usuario",
    creationDate: "Fecha de Creación",
    updateDate: "Fecha de Actualización",
    logs: "Registros",
  },
  notificationsPage: {
    title: "Enviar notificaciones",
    subtitle: "Enviar correos electrónicos y notificaciones a sus clientes.",
    newNotification: "Nueva notificación",
    settingYourNotification: "Configuración de su notificación.",
    generalData: "Datos generales",
    settingNotificationParams: "Configurar parámetros de notificación",
    selectRecipientType: "Seleccionar tipo de destinatario",
    to: "Para",
    recipient: "Destinatario",
    subject: "Asunto",
    message: "Mensaje",
    sendNow: "Enviar ahora",
    subjects: {
      finance: "Finanzas",
      insufficientBalance: "Saldo insuficiente",
      trackingIssue: "Problema de seguimiento",
      newTicket: "Nuevo ticket",
    },
    seller: "Vendedor",
    user: "Usuario",
    readed: "Leído",
    seeMessage: "Ver mensaje",
    notification: "Notificación",
    seeSendedNotification: "Ver el mensaje que fue enviado",
    sendedMessage: "Mensaje enviado",
    sendDate: "Fecha de envío",

    createNotification: "Crear Notificaciones",
    body: "Mensaje",
    respondApprovalRequest: "Responder a la solicitud de aprobación",
    notificationMessage: "Mensaje de Notificación",
    contract: "Contrato",
    name: "Nombre",
    file: "Archivo",
    validityDate: "Fecha de Validez",
    contracts: "Contratos",
    click: "Clic",
    hERE: "AQUÍ",
    seeDetailedData: "para ver los datos detallados",
    thisRequest: "Este pedido",
    is: "es",
    hasBeen: "fue",
    supervisorCommented: "El supervisor comentó",
    addCommentResponse: "Puedes agregar un comentario a tu respuesta",
    approve: "Aprobar",
    deny: "Denegar",
    by: "por",
    ChooseRecipientType: "Escoge el tipo de destinatario*",
    Seller: "Vendedor",
    User: "Usuario",
    Title: "Título",
    finance: "Finanza",
    insufficientBalance: "Saldo Insuficiente",
    trackingIssue: "Problema de Seguimiento",
    newTicket: "Nuevo Ticket",
    dataUpdate: "Editar Fecha",
    contractPermission: "Permiso de Contrato",
    contractUpdate: "Editar Contrato",
    priceTableUpdate: "Actualizar Tabla de Precios",
    generalIssue: "Problema general",
    fields: {
      newRead: "Nuevo/Leído",
      tType: "Tipo",
      recipient: "Destinatario",
      title: "Título",
      body: "Mensaje",
    },
    titleNotification: "Título",
    type: "Tipo",
    new: "Nuevo",
    recipientType: "Tipo de destinatario",
  },
  rejectedOrderPage: {
    title: "Registros de Pedidos Rechazados",
    subTitle:
      "Esta página presenta todos los pedidos de importación fallidos que sus vendedores intentaron crear en su página de importación. Puede ver los parámetros importados, así como el error que causó el fallo en la importación.",
    fields: {
      orderNumber: "Número del Pedido",
      dateImport: "Fecha de importación",
      orderInfo: "Información del Pedido",
      sellerID: "Vendedor ID",
      error: "Error",
    },
  },
  rejectedOrderViewPage: {
    title: "Registro de Pedidos Rechazados #",
    seller: "Vendedor",
    creationDate: "Fecha de Creación",
    orderInfo: "Información del Pedido",
    erroInfo: "Información errores",
  },
  trackingRulesPage: {
    title: "Reglas de Seguimiento",
    subTitle:
      "Automatiza notificaciones cuando ocurran ciertos eventos de seguimiento",
    addTrackingRule: "Agregar Regla de Seguimiento",
    addNewTrackingRule: "Agregar Nueva Regla de Seguimiento",
    trackingMode: "Modo de Seguimiento",
    receivedCheckpoint: "Punto de Control Recibido",
    timeBetweenTwoCheckpoints: "Tiempo entre los dos puntos de control ",
    name: "Nombre",
    contract: "Contrato",
    incoterm: "Términos Internacionales de Comercio",
    any: "Cualquiera",
    startCheckpoint: "Punto de Control Inicial",
    endCheckpoint: "Punto de Control Final",
    maximumDays: "Máximo de Días",
    checkpoint: "Punto de Control",
    log: "Registro",
    notifySeller: "Notificar al vendedor",
    sellerNotification: "Notificación del Vendedor",
    notifyCustomer: "Notificar Cliente",
    notifyNobordist: "Notificar Nobordist",
    webhook: "Webhook",
    method: "Método",
    pOST: "POST",
    pUT: "PUT",
    headers: "Encabezados",
    addHeaders: "Agregar Encabezado",
    key: "Llave de autenticación",
    value: "Valor",
    seller: "Vendedor",
    titleRules: "Título",
    fields: {
      name: "Nombre",
      startCheckpoint: "Punto de Control Inicial",
      endCheckpoint: "Punto de Control Final",
      incoterm: "Términos Internacionales de Comercio",
      seller: "Vendedor",
      contract: "Contrato",
      daysLimit: "Límite de Días",
      mode: "Modo",
      creationDate: "Fecha de Creación",
    },
    allSellerNotificationTitles: {
      finance: "Finanza",
      insufficientBalance: "Saldo Insuficiente",
      trackingIssue: "Problema de Seguimiento",
    },
    chooseTrigger: "Elija el disparador que desea usar para aplicar la regla:",
    whenSpecificCheckpointOccur: "Cuando ocurre un punto de control específico",
    whenDelayIsTooLong:
      "Cuando el retraso es demasiado largo entre 2 puntos de control",
    applyRuleToSellersHelpText:
      "Puede aplicar la regla a todos los vendedores dejando este campo sin seleccionar.",
    applyRuleToContractsHelpText:
      "Puede aplicar la regla a todos los contratos dejando este campo sin seleccionar.",
    amountToWaitCheckpointsHelpText:
      "El tiempo máximo a esperar entre los 2 puntos de control",
    endCheckpoointHelpText:
      "El punto de control final objetivo; si quieres aplicarlo a cualquier punto de control, simplemente deja este campo sin seleccionar",
    giveNameToYourRule: "Dale un nombre a tu regla",
    chooseWhatWillBeSent: "Elige qué se enviará",
    logHelpText:
      "Crea un Registro de Alerta de Seguimiento en el sitio administrativo",
    notifySellerHelpText: "Envía un correo electrónico al vendedor registrado",
    notifyCustomerHelpText:
      "Envía un correo electrónico al cliente utilizando la dirección de correo electrónico informada en el pedido",
    notifyNbHelpText:
      "Envía un correo electrónico a algún usuario administrador o correo electrónico corporativo general",
    sellerNotificationHelpText:
      "Envía una notificación al vendedor en su cuenta del sitio",
    webhookHelpText: "Envía un webhook a cualquier API",
    sellerInternalNotification: "Notificación Interna del Vendedor",
  },
  trackingRulesViewPage: {
    title: "Regla de Seguimiento",
    editTrackingRule: "Editar Regla de Seguimiento",
    deleteTrackingRule: "Borrar Regla de Seguimiento",
    confirmDeleteRule: "¿Está seguro de que desea eliminar esta regla?",
    edit: "Editar",
    name: "Nombre",
    contract: "Contrato",
    startCheckpoint: "Punto de Control Inicial",
    endCheckpoint: "Punto de Control Final",
    maximumDays: "Máximo de Días",
    log: "Registro",
    notifySeller: "Notificar al Vendedor",
    notifyCustomer: "Notificar Cliente",
    notifyNobordist: "Notificar Nobordist",
    webhook: "Webhook",
    method: "Método",
    pOST: "POST",
    pUT: "PUT",
    headers: "Encabezados",
    key: "Llave de autenticación",
    value: "Valor",
    addHeaders: "Agregar Encabezados",
    sureWantDelete:
      "Estás seguro de que deseas borrar esta regla de seguimiento",
    seller: "Vendedor",
    daysLimit: "Límite de Días",
    trackingMode: "Modo de Seguimiento",
    sellerMailTitle: "Título del correo electrónico del vendedor",
    sellerMailBody: "Cuerpo del correo electrónico del vendedor",
    customerMailTitle: "Título del Correo electrónico del Cliente",
    customerMailBody: "Cuerpo del Correo electrónico del Cliente",
    nobordistMailTitle: "Título del Correo electrónico Nobordist",
    destination: "Destinatario",
    nobordistMailBody: "Cuerpo del Correo electrónico Nobordist",
    notifyWebhook: "Webhook de notificación",
    endpoint: "Endpoint",
    body: "Mensaje",
    noActionConfigured: "No hay ninguna acción configurada",
  },
  userNotificationsPage: {
    title: "Notificaciones del Usuario",
    respondRequest: "Responder a la solicitud de aprobación",
    notificationMessage: "Mensaje de Notificación",
    name: "Nombre",
    file: "Archivo",
    validityDate: "Fecha de Validez",
    contracts: "Contratos",
    contract: "Contrato",
    click: "Clic",
    hERE: "AQUÍ",
    seeDetailedData: "para ver los datos detallados",
    thisRequest: "Este pedido",
    is: "es",
    hasBeen: "fue",
    by: "por",
    supervisorCommented: "El supervisor comentó",
    addCommentResponse: "Puedes agregar un comentario a tu respuesta",
    approve: "Aprobar",
    deny: "Denegar",
    seller: "Vendedor",
    fields: {
      data: "Fecha",
      title: "Título",
      body: "Mensaje",
    },
    filterOptions: {
      title: "Título",
      seller: "Vendedor",
      body: "Mensaje",
      new: "Nuevo",
      status: "Status",
      read: "Leer",
    },
    allSellerNotificationTitles: {
      finance: "Finanza",
      insufficientBalance: "Saldo Insuficiente",
      trackingIssue: "Problema de Seguimiento",
    },
    sellerNotificationTitles: {
      finance: "Finanza",
      insufficientBalance: "Saldo Insuficiente",
      trackingIssue: "Problema de Seguimiento",
      newTicket: "Nuevo Ticket",
    },
    userNotificationTitles: {
      finance: "Finanza",
      insufficientBalance: "Saldo Insuficiente",
      trackingIssue: "Problema de Seguimiento",
      newTicket: "Nuevo Ticket",
      dataUpdate: "Actualizar Datos",
      contractPermission: "Permisos de Contrato",
      contractUpdate: "Editar Contrato",
      priceTableUpdate: "Editar Tabla de Precios",
    },
  },
  myNotificationsListPage: {
    title: "Notificaciones",
    found: "no se encontraron notificaciones | notificación | notificaciones",
    filters: {
      isNew: "Nueva o Leída",
    },
    allNotifications: "Todas las notificaciones",
    youHaveNewNotification: "Tienes nuevas notificaciones sin leer.",
  },
  platformsPage: {
    title: "Plataformas",
    addPlatform: "Agregar Plataformas",
    AddNewPlatform: "Agregar Nueva Plataforma",
    platformName: "Nombre de la Plataforma",
    correiosCredentials: "Credenciales de los Correos",
    addDispatch: "Agregar Despacho",
    key: "Llave de autenticación",
    value: "Valor",
    fields: {
      name: "Nombre",
      actions: "Acciones",
    },
  },
  flightsPage: {
    title: "Vuelos",
    subtitle: "Consulta e informa en qué vuelo se encuentra cada pedido.",
    nextFlights: "Próximos vuelos",
    createNewFlight: "Crear nuevo vuelo",
    newFlight: "Nuevo vuelo",
    selectMasterboxes: "Seleccionar cajas maestras",
    findAndSelectMasterboxes:
      "Busca y selecciona las masterboxes que deseas agregar a tu vuelo",
    typeMasterboxesReference:
      'Escriba la referencia de Masterbox y presione "Entrar"',
    selectedMasterboxes: "Cajas maestras seleccionadas",
    reference: "Referencia",
    next: "Proceder",
    insertFlightData: "Ingrese los detalles del vuelo",
    pleaseInsertFlightData:
      "Ingrese los detalles del vuelo para agregar masterboxes",
    howWouldLikeToUploadMawb: "¿Cómo te gustaría subir el MAWB?",
    generalData: "información general",
    departureAndArrive: "Salida y llegada",
    taxes: "Honorarios",
    returnsToPreviousStep: "Volver al paso anterior",
    createFlight: "Crear vuelo",
    uploadFromMyComputer: "Subir desde mi computadora",
    documentsAndLabels: "Documentos y etiquetas",
    editFlightData: "Editar datos de vuelo",
    suggestedActions: "Acciones sugeridas",
    copyLabelCode: "Copiar código de etiqueta",
    taxesAndOthers: "Tarifas y otros",
    masterboxes: "cajas maestras",
    departure: "Fósforo",
    stopover: "Escala",
    arrival: "Llegada",
    noStopOver: "Vuelo directo",
    closeManifestConfirm:
      "¿Quieres cerrar este manifiesto? Ya no podrás actualizar tu contenido después de esta acción.",
    closeManifest: "Cerrar manifiesto",
    confirmDeparture: "Confirmar salida",
    deleteFlight: "Eliminar vuelo",
    fields: {
      airline: "Compañía Aérea",
      flightNumber: "Número de Vuelo",
      flightLabel: "Etiqueta de Vuelo",
      mawbNumber: "Número Mawb",
      greenLight: "Green Light",
      prealertsSent: "Prealertas enviadas",
      departureAirport: "Aeropuerto de Salida",
      departureDate: "Fecha de Salida",
      departureTime: "Hora de Salida",
      arrivalAirport: "Aeropuerto de Llegada",
      arrivalDate: "Fecha de Llegada",
      arrivalTime: "Hora de Llegada",
      stopoverAirport: "Aeropuerto de Escala",
      freightValue: "Valor del Flete",
      creationDate: "Fecha de Creación",
    },
  },
  flightsViewPage: {
    title: "Vuelo #",
    mawbLabel: "Etiqueta de Vuelo",
    shipments: "Remesas",
    pages: "Páginas",
    closeManifest: "Cerrar Manifiesto",
    confirmDeparture: "Confirmar Salida",
    wantRemoveFlight: "Deseas eliminar este vuelo permanentemente",
    departureAirport: "Aeropuerto de Salida",
    arrivalAirport: "Aeropuerto de Llegada",
    stopoverAirport: "Aeropuerto de Escala",
    departureDate: "Fecha de Salida",
    arrivalDate: "Fecha de Llegada",
    departureTime: "Hora de Salida",
    arrivalTime: "Hora de Llegada",
    airline: "Compañía Aérea",
    flightNumber: "Número del Vuelo",
    subtotal: "Subtotal",
    chargesKilo: "Cargos por kilo",
    otherCharges: "Otros Cargos",
    mawbNumber: "Número Mawb",
    mawbFileFormat: "Formato de Archivo Mawb",
    binary: "binário",
    link: "link",
    mawbFile: "Archivo Mawb",
    copyReference_id: "clic aquí para copiar el reference_id",
    greenLight: "Green Light",
    prealertsSend: "Envío de prealertas",
    date: "Fecha",
  },
  masterboxesPage: {
    title: "Masterboxes",
    subtitle: "Filtre y verifique cajas con volúmenes y pedidos.",
    createMasterbox: "Crear masterbox",
    documentsAndLabels: "Documentos y etiquetas",
    suggestedActions: "Acciones sugeridas",
    masterboxData: "Datos de la masterbox",
    labels: "Etiquetas",
    mbCarriers: "Transportadoras",
    reference: "Referencia",
    shipmentID: "ID de envío",
    shipmentSequenceNumber: "Número de secuencia de envío",
    date: "Fecha",
    cN35Label: "Etiqueta CN35",
    volumes: "Volúmenes",
    dispatchID: "ID de despacho",
    goToShipment: "Ver envío",
    creationDate: "Fecha de creación",
    weight: "Peso",
    lastMileTrackingNumber: "Número de seguimiento de la última milla",
    lastMileCarrierNumber: "Número de la transportadora de la última milla",
    totalItems: "Artículos totales",
    deleteMasterbox: "Eliminar Masterbox",
    confirmDeleteMasterbox: "¿Desea eliminar esta masterbox permanentemente?",
    selectVolumes: "Seleccionar volúmenes",
    selectedVolumes: "Volúmenes seleccionados",
    findAndSelectVolumes:
      "Busque y seleccione los volúmenes que desea agregar a la caja maestra",
    typeVolumeReference: 'Escriba la referencia de volumen y presione "Entrar"',
    downloadMasterboxErrors: "Descargar CSV de errores de Masterbox",
    commentDownloadMasterboxErrors:
      "* Solo se agregarán al csv los volúmenes con número de seguimiento de última milla",
    goToFlight: "Ir a vuelo",
    volumesCount: "Cantidad de Volúmenes",
  },
  masterboxesViewPage: {
    title: "Masterbox #",
    dispatchID: "ID de despacho",
    reference: "referencia",
    shipmentID: "ID de la remesa",
    shipmentSequenceNumber: "Número de Secuencia de Remesa",
    date: "Fecha",
    cN35Label: "Etiqueta CN35",
    volumes: "Volúmenes",
    copyReference_id: "clic aquí para copiar el reference_id",
    fields: {
      weight: "Peso",
      lastMileTrackingNumber: "Número de seguimiento de la última milla",
      lastMileCarrierNumber: "Número de la transportadora de la última milla",
      totalItems: "Artículos totales",
    },
  },
  ordersPage: {
    title: "Gestión de Pedidos",
    subtitle: "Filtrar y verificar pedidos, descargar informes y más.",
    importMabangOrders: "Importar Pedidos de Mabang",
    importOrderCosts: "Importar Custo de Pedidos",
    downloadOrders: "Descargar Pedidos",
    downloadOrdersCosts: "Descargar Costos de Pedidos",
    updateFinancialStatus: "Actualizar Situación Financiera",
    lastMileCarrierNotFound:
      "Nº de la transportadora de la última milla no encontrado",
    new: "Nuevo",
    invoiced: "Facturado",
    toInvoice: "Facturar",
    canceled: "Cancelado",
    notCharged: "No Cobrado",
    checkDownloadsAt:
      "Revisa las descargas de procesamiento en... página de descargas.",
    selectOption: "Por favor selecciona una opción",
    orderNo: "Pedido N°",
    fieldsCurrentOrderCosts: {
      externalID: "ID Externo",
      type: "Tipo",
      value: "Valor",
      creationDate: "Fecha de Creación",
    },
    financial_statuses: {
      new: "Nuevo",
      toInvoice: "Facturar",
      invoiced: "Facturado",
      canceled: "Cancelado",
      notCharged: "No Cobrado",
    },
    fields: {
      sellerID: "Vendedor ID",
      sellerName: "Nombre del vendedor",
      sellerTaxNumber: "Número fiscal del vendedor",
      contractID: "Contrato ID",
      overpackID: "Paquete ID",
      customerName: "Nombre del Cliente",
      postalCode: "Código Postal",
      customerCity: "Ciudad del Cliente",
      customerState: "Estado del Cliente",
      incoterm: "Términos Internacionales de Comercio",
      orderNumber: "Número del Pedido",
      salesChOrderN: "Nº de Pedido del Canal de Ventas",
      productsValue: "Valor de los Productos",
      freightValue: "Valor del flete",
      declaredValue: "Valor declarado",
      dutiesTaxValue: "Valor del impuesto de derechos",
      salesTaxValue: "Valor del impuesto sobre ventas",
      taxValue: "Valor Fiscal",
      totalValue: "Valor Total",
      estimatedDutiesTaxValue: "Valor de Impuesto Estimado de Derechos",
      estimatedSalesTaxValue: "Valor estimado del impuesto sobre ventas",
      estimatedTaxValue: "Valor fiscal estimado",
      freightCost: "Costo del Flete",
      estimatedFreightCost: "Costo estimado de flete",
      taxCost: "Costo fiscal",
      insuranceCost: "Costo del seguro",
      insuranceCoverage: "Cobertura del seguro",
      dDPCost: "Costo DDP",
      totalGeneralCosts: "Costos Generales Totales",
      trackingNumber: "Número de Seguimiento",
      lastMileCarrierNumber: "Número de la transportadora de la última milla",
      isMini: "¿Es Mini?",
      financialStatus: "Status financiero",
      creationDate: "Fecha de Creación",
      mSDS: "MSDS",
      label: "Etiqueta",
      firstMileLabel: "Etiqueta de primera milla",
      shipConfirmation: "Confirmación de envío",
      isLanded: "¿Está aterrizado?",
    },
    removeOrdersCount: "Eliminar %{count} pedidos",
    removeOrders: "Eliminar pedidos",
    toBeRemoved:
      "Los %{count} pedidos restantes están disponibles para su eliminación. ¿Está seguro que quiere eliminarlos? Esta acción es irreversible.",
  },
  ordersViewPage: {
    title: "Pedido #",
    label: "Etiqueta",
    addCost: "Agregar Costo",
    commercialInvoice: "Factura Comercial",
    trackingPage: "Página de Seguimiento",
    packingSlip: "Guía de Remesa",
    cevaShippersConfirmation: "Confirmación Ceva Remitentes",
    cUSTOMER: "CLIENTES",
    relatedObjects: "OBJETOS RELACIONADOS",
    freight: "FLETE",
    editOrder: "Edit Order",
    generalInformations: "INFORMACIÓN GENERAL",
    valuesSummary: "RESUMEN DE VALORES",
    generalCosts: "Gastos Generales",
    volumes: "Volúmenes",
    costType: "Tipo de Costo",
    selecttype: "Selecciona el tipo",
    value: "Valor",
    name: "Nombre",
    documentType: "Tipo de Documento",
    documentNumber: "Número de Documento",
    address: "Dirección",
    addressNumber: "Número de Dirección",
    addressComplement: "Complemento de Dirección",
    addressReference: "Referencia de Dirección",
    postalCode: "Código Postal",
    addressQuarter: "Barrio de la Dirección",
    city: "Ciudad",
    state: "Estado",
    country: "País",
    phone: "Teléfono",
    email: "Correo electrónico",
    mailbox: "Buzón de correo",
    phoneCountryCode: "Teléfono Código del País",
    salesChannelID: "ID del canal de ventas",
    sellerIDcomp: "Vendedor ID comp",
    contractID: "Contrato ID",
    overpackID: "Paquete ID",
    currency: "Divisa",
    incoterm: "Términos Internacionales de Comercio",
    returnInsurance: "Seguro Devolución",
    insuranceCoverage: "Cobertura del Seguro",
    returnModality: "Modalidad de Devolución",
    freightValue: "Valor del Flete",
    orderNumbe: "Número de Pedido",
    createdDate: "Fecha de Creación",
    sellerName: "Nombre del Vendedor",
    estimatedDeliveryDate: "Fecha de Entrega Estimada",
    trackingNumber: "Número de Seguimiento",
    isMini: "Es Mini",
    observation: "Observación",
    declaredValue: "Valor declarado",
    taxValue: "Valor Fiscal",
    total: "Total",
    remove: "Eliminar",
    wantToRemore:
      "¿Está seguro de que desea eliminar este pedido? Esta operación es irreversible",
    estimatedTaxValue: "Valor fiscal estimado",
    freightCost: "Costo del Flete",
    volumesFields: {
      Weight: "Peso",
      cubedWeight: "Peso Cubado",
      lastMileTrackingNumber: "Número de seguimiento de la última milla",
      lastMileCarrierNumber: "Número de la transportadora de la última milla",
      productValue: "Valor del Producto",
      freightValue: "Valor del flete",
      estimatedFreightCost: "Costo estimado de flete",
      taxValue: "Valor Fiscal",
      totalItems: "Artículos totales",
      updatedWeight: "Updated Weight",
    },
    fieldsCurrentOrderCosts: {
      externalID: "ID Externo",
      type: "Tipo",
      value: "Valor",
      creationDate: "Fecha de Creación",
    },
    orderData: "Datos del pedido",
    customerData: "Datos del cliente",
    shippingInfo: "Información de envío",
    reviewValues: "Revisar valores",
    copyTrackingNumber: "Copiar número de seguimiento",
    showAllCustomerData: "Mostrar todos los datos del cliente",
    colapsoCustomerData: "Colapso de datos del cliente",
    showAllOrderData: "Mostrar todos los datos del pedido",
    colapsoOrderData: "Contraer datos del pedido",
  },
  overpacksPage: {
    title: "Gestión de Paquetes",
    updateFirstMileInformation: "Actualizar Información de Primera Milla",
    carriername: "Nombre de la Transportadora",
    trackingNumber: "Número de Seguimiento",
    updateInformation: "Actualizar Información",
    updateTrackingStatus: "Actualizar Status de Seguimiento",
    updateOverpacks: "Actualizar Overpacks",
    overpackSelected: "Paquete Seleccionado",
    selectOneStatus: "Seleccionar un Status",
    statusSelected: "Status Seleccionado",
    fields: {
      sentDate: "Fecha de envío",
      trackingNumber: "Número de Seguimiento",
      masterboxID: "Masterbox ID",
      fMTrackingN: "N° de Seguimiento PM",
      firstMileCompany: "Compañía de la Primera Milla",
      creationDate: "Fecha de Creación",
    },
    trackingOptions: {
      forwardedlocalhub: "La remesa fue enviada hacia un hub local",
      arrivedHubSorting: "La remesa llegó a un hub o centro de clasificación",
      clearanceCompleted: "El proceso de despacho aduanero se completó",
      shipmentArrivedDestinationCountry:
        "La remesa internacional llegó al país de destino",
      handedCustomsCustomsClearance:
        "La remesa fue entregada a la aduana para el proceso de despacho de aduana",
      customsClearanceCompleted: "El proceso de despacho aduanero se completó",
      arrivedHubSortingCenter:
        "La remesa llegó a un hub o centro de clasificación",
    },
  },
  overpacksViewPage: {
    title: "Paquete",
    new: "Nuevo",
    ready: "Preparar",
    dispatched: "Despachado",
    orders: "Pedidos",
    orderFoundOverpack: "No se encontró ningún pedido para el Paquete #",
    cN23Numbers: "CN23 Números",
    cN23foundforOverpack: "No se encontró ningún CN23 para el Paquete #",
    masterboxID: "Masterbox ID",
    createdDate: "Fecha de Creación",
    sentDate: "Fecha de envío",
    trackingNumber: "Número de Seguimiento",
    firstMileCarrierName: "Nombre de la Transportadora de la Primera Milla",
    firstMileTrackingN: "N° de Seguimiento de la Primera Milla",
    fields: {
      orderNumber: "Número de Pedido",
      trackingNumber: "Número de Seguimiento",
      creationDate: "Fecha de Creación",
      customerName: "Nombre del Cliente",
    },
  },
  shipmentsPage: {
    title: "Remesas",
    subtitle: "Filtrar y verificar envíos, descargar informes y más.",
    downloadShipments: "Descargar Remesas",
    actualWeight: "Peso Actual",
    checkProcessingDownloads:
      "Revisa las descargas de procesamiento en... página de descargas",
    goToFlight: "Ir a vuelo",
    fields: {
      cn38: "cn38",
      cn38Label: "Etiqueta cn38",
      sSellerWeight: "Peso - Vendedor",
      actualWeight: "Peso Actual",
      category: "Categoría",
      flightID: "Vuelo ID",
      creationDate: "Fecha de Creación",
    },
    filterOptions: {
      sellerStartWeight: "Vendedor - Peso Inicial",
      sellerEndWeight: "Vendedor - Peso Final",
      actualStartWeight: "Peso Actual Inicial",
      actualEndWeight: "Peso Actual Final",
    },
  },
  shipmentsViewPage: {
    title: "Remesa #",
    category: "Categoría",
    flightID: "Vuelo ID",
    cn38: "cn38",
    date: "Fecha",
    cn38Label: "Etiqueta cn38",
    masterboxes: "Masterboxes",
    pages: "Páginas",
    fields: {
      reference: "Referencia",
      dispatchID: "ID de despacho",
      lastMileTrackingNumbers: "Números de seguimiento de la última milla",
    },
  },
  volumeReversePage: {
    title: "Inverso de los Volúmenes",
    subtitle: "Filtrar y consultar pedidos, descargar informes y más",
    fields: {
      orderNumber: "Número de Pedido",
      orderID: "Pedido ID",
      volumeID: "Volumen ID",
      sellerID: "Vendedor ID",
      name: "Nombre",
      creationdate: "Fecha de Creación",
      reverseNumber: "Número del Inverso",
      address: "Dirección",
      addressNumber: "Número de la Dirección",
      addressComplement: "Complemento de la Dirección",
      state: "Estado",
      postalCode: "Código Postal",
      country: "País",
      city: "Ciudad",
      customerName: "Nombre del Cliente",
      orderItemsName: "Nombre de los artículos del Pedido",
      orderItemsdescription: "Descripción de los artículos del Pedido",
      orderItemsHSCode: "HS Code de los Artículos del Pedido",
      startRegistrationDate: "Fecha de comienzo del registro",
      endRegistrationDate: "Fecha final del registro",
      order: "Pedido",
      seller: "Vendedor",
      volume: "Volume",
    },
  },
  volumeReverseViewPage: {
    title: "Inverso del Volumen #",
    volumeReverse: "INVERSO DEL VOLUMEN",
    order: "PEDIDO",
    volume: "VOLUMEN",
    customer: "CLIENTE",
    items: "artículos",
    reverseNumber: "Número del Inverso",
    createdDate: "Fecha de Creación",
    name: "Nombre",
    address: "Dirección",
    addressNumber: "Número de la Dirección",
    addressComplement: "Complemento de la Dirección",
    state: "Estado",
    postalCode: "Código Postal",
    country: "País",
    city: "Ciudad",
    orderID: "Pedido ID",
    orderNumber: "Número de Pedido",
    salesChannelOrderNumber: "Número de Pedido del Canal de Ventas",
    totalValue: "Valor Total",
    trackingNumber: "Número de Seguimiento",
    volumeID: "Volume ID",
    height: "Alto",
    width: "Ancho",
    length: "Largo",
    weight: "Peso",
    lastMileTrackingNumber: "Número de seguimiento de la última milla",
    documentType: "Tipo de Documento",
    documentNumber: "Número do Documento",
    addressReference: "Referecia de la Dirección",
    addressQuarter: "Barrio de la Dirección",
    phone: "Teléfono",
    email: "Correo electrónico",
    mailbox: "Buzón de correo",
    phoneCountryCode: "Teléfono Código del País",
    fields: {
      name: "Nombre",
      value: "Valor",
      hsCode: "Hs Code",
      Quantity: "Cantidad",
      originCountry: "País de Origen",
    },
  },
  volumesPage: {
    title: "Volúmenes",
    new: "Nuevo",
    invoiced: "Facturado",
    toInvoice: "Facturar",
    canceled: "Cancelado",
    notCharged: "No Cobrado",
    downloadMasterboxErrors: "Descargar CSV de errores de Masterbox",
    commentDownloadMasterboxErrors:
      "* Only volumes with last_mile_tracking_number will be added to the csv",
    wantCreateMasterbox:
      "¿Quieres crear una masterbox con los volúmenes seleccionados a continuación?",
    volumes: "volúmenes",
    copyReference_id: "clic aquí para copiar el reference_id",
    downloadVolumes: "Download volúmenes",
    createMasterbox: "Crear masterbox",
    fields: {
      cubed_weight: "Peso Cubado",
      weight: "Peso",
      height: "Alto",
      Width: "Ancho",
      length: "Largo",
      reference: "Referencia",
      lastMileCarrierNumber: "Número de la transportadora de la última milla",
      lastMileTrackingNumber: "Número de seguimiento de la última milla",
      collectedIcms: "ICMS recopilados",
      collectedIi: "II recopilados",
      orderIsCommercialDestination: "Orden Es Destino Comercial",
      isCommercialDestination: "Es Destino Comercial",
      orderSellerRfbCode: "Código RFB del Vendedor de la Orden",
      sellerRfbCode: "Código RFB del Vendedor",
      orderSellerLegalName: "Nombre Legal del Vendedor de la Orden",
      sellerLegalName: "Nombre Legal del Vendedor",
      firstMileTrackingNumber: "First Mile Tracking Number",
      cN23: "CN23",
      firstMileLabel: "First Mile Label",
      creationDate: "Fecha de Creación",
      volumeProductsValue: "Valor de los productos del volumen",
      volumeFreightValue: "Valor del flete del volumen",
      volumeDeclaredValue: "Valor declarado del volumen",
      volumeDutiesTaxValue: "Valor del impuesto sobre el Volumen",
      volumeSalesTaxValue: "Valor del impuesto sobre ventas por volumen",
      volumeTaxValue: "Valor del impuesto de volumen",
      volumeTotalValue: "Volumen Total Value",
      volumeEstimatedDutiesTaxValue: "Valor de Impuesto Estimado de Volumen",
      volumeEstimatedSalesTaxValue:
        "Valor estimado del impuesto sobre ventas por volumen",
      volumeEstimatedTaxValue: "Valor de impuesto estimado de volumen",
      volumeFreightCost: "Costo de flete por volumen",
      volumeEstimatedFreightCost: "Costo de Flete Estimado por Volumen",
      hSCode: "HS Code",
      description: "Descripción",
      currentStatus: "Status actual",
      updatedWeight: "Peso actualizado",
      orderID: "Pedido ID",
      mSDS: "MSDS",
      sellerID: "Vendedor ID",
      firstMileN: "Primera Milla Nº",
      contractID: "Contrato ID",
      customerName: "Nombre del Cliente",
      cPostalCode: "C. Código Postal",
      customerCity: "Ciudad del Cliente",
      customerState: "Estado del Cliente",
      customerAddress: "Dirección del Cliente",
      customerAddressNumber: "Número de Dirección del Cliente",
      customerAddressComplement: "Complemento de Dirección del Cliente",
      customerAddressReference: "Referencia de Dirección del Cliente",
      customerAddressQuarter: "Barrio de la Dirección del Cliente",
      customerDocumentType: "Tipo de Documento del Cliente",
      customerDocumentNumber: "Número de Documento del Cliente",
      customerCountry: "País del Cliente",
      customerEmail: "Correo electrónico Cliente",
      customerPhone: "Teléfono del Cliente",
      currency: "Divisa",
      estimatedDeliveryDate: "Fecha de Entrega Estimada",
      incoterm: "Términos Internacionales de Comercio",
      orderNumber: "Número de Pedido",
      salesChannel: "Canal de Ventas",
      salesChOrderNumber: "N° Pedido del Canal de Ventas",
      totalValue: "Valor Total",
      orderReference: "Referencia del Pedido",
      financialStatus: "Status Financiero",
      orderProductsValue: "Valor de los Productos del Pedido",
      orderFreightValue: "Valor del Flete del Pedido",
      orderDeclaredValue: "Valor Declarado del Pedido",
      orderDutiesTaxValue: "Valor del Impuesto de Derechos del Pedido",
      orderSalesTaxValue: "Valor del Impuesto Sobre Ventas del Pedido",
      orderTaxValue: "Valor del Impuesto del Pedido",
      orderTotalValue: "Valor Total del Pedido",
      orderEstimatedDutiesTaxValue:
        "Valor del Impuesto Estimado de Derechos del Pedido",
      orderEstimatedSalesTaxValue:
        "Valor Estimado del Impuesto Sobre Ventas del Pedido",
      orderEstimatedTaxValue: "Valor Fiscal Estimado del Pedido",
      orderFreightCost: "Costo de Flete del Pedido",
      overpackID: "Paquete ID",
      overpackSentDate: "Fecha de envío del Paquete",
      overpackReference: "Referencia del Overpack",
      fMTrackingNumber: "PM Numero de Seguimiento",
      firstMileCompany: "Compañía de la Primera Milla",
      masterboxID: "Masterbox ID",
      dispatchID: "ID de despacho",
      cN35: "CN35",
      cN35Label: "Etiqueta CN35",
      shipmentID: "ID de la remesa",
      shipmentCategory: "Categoría de Envío",
      cN38: "CN38",
      cN38Label: "Etiqueta CN38",
      flightID: "Vuelo ID",
      flightN: "Vuelo Nº",
      flightMAWB: "Vuelo MAWB",
      mAWBLabel: "Etiqueta MAWB",
      flightReference: "Referencia del Vuelo",
      airline: "Compañía Aérea",
      greenLight: "Green Light",
      prealertsSent: "Prealertas enviadas",
      departureAirport: "Aeropuerto de Salida",
      arrivalAirport: "Aeropuerto de llegada",
      departureStartDate: "Início de la Fecha de Salida",
    },
    filterOptions: {
      volumeStartDate: "Fecha de Início del Volumen",
      volumeEndDate: "Fecha Final del Volumen",
      volumeID: "Volumen ID",
      reference: "Referencia",
      lastMileTrackingNumber: "Número de seguimiento de la última milla",
      lmtnPopover:
        "Acepta valores separados por: comas, punto y coma, espacios o saltos de línea",
      withoutLastMileTrackingNumber:
        "Sin Número de seguimiento de la última milla",
      lastMileCarrierNumber: "Número de la transportadora de la última milla",
      estimatedFreightCost: "Costo estimado del flete",
      orderStartDate: "Fecha Inicial del Pedido",
      orderEndDate: "Fecha final del Pedido",
      orderID: "Pedido ID",
      contractID: "Contrato ID",
      sellerID: "Vendedor ID",
      freightCost: "Costo del Flete",
      firstMileN: "Primera Milla Nº",
      lastMileN: "Última Milla Nº",
      orderNumber: "Número de Pedido",
      salesChannel: "Canal de Ventas",
      salesChOrderNumber: "N° Pedido del Canal de Ventas",
      orderReference: "Referencia del Pedido",
      incoterm: "Términos Internacionales de Comercio",
      customerName: "Nombre del Cliente",
      customerAddress: "Dirección del Cliente",
      customerAddressNumber: "Número de Dirección del Cliente",
      customerAddressComplement: "Complemento de Dirección del Cliente",
      customerAddressReference: "Referencia de Dirección del Cliente",
      customerAddressQuarter: "Barrio de la Dirección del Cliente",
      customerDocumentType: "Tipo de Documento del Cliente",
      customerDocumentNumber: "Número de Documento del Cliente",
      customerCountry: "País del Cliente",
      customerEmail: "Correo electrónico del Cliente",
      customerPhone: "Teléfono del Cliente",
      financialStatus: "Status Financiero",
      overpackStartDate: "Fecha inicial del Paquete",
      overpackEndDate: "Fecha final del paquete",
      overpackStartSendDate: "Fecha inicial de Envío del Paquete",
      overpackEndSendDate: "Fecha Final de Envío del Paquete",
      overpackID: "Paquete ID",
      overpackReference: "Referencia del Overpack",
      bagNumber: "Número de Bolsa",
      firstMileTrackingN: "N° de Seguimiento de la primera milla",
      firstMileCarrierName: "Nombre de la Transportadora de la Primera Milla",
      masterboxStartDate: "Fecha inicial Masterbox",
      masterboxEndDate: "Fecha final Masterbox",
      masterboxID: "Masterbox ID",
      cn35: "cn35",
      dispatchID: "ID de despacho",
      shipmentStartDate: "Fecha inicial de la remesa",
      shipmentEndDate: "Fecha final de la remesa",
      shipmentID: "ID de la remesa",
      cN38: "CN38",
      shipmentCategory: "Categoría de Envío",
      flightStartDate: "Fecha inicial del vuelo",
      flightEndDate: "Fecha final del vuelo",
      flightID: "Vuelo ID",
      flightMAWB: "Vuelo MAWB",
      flightN: "Vuelo Nº",
      flightreference: "Referencia del Vuelo",
      airline: "Compañía Aérea",
      greenLight: "Green Light",
      departureAirport: "Aeropuerto de Salida",
      arrivalAirport: "Aeropuerto de Llegada",
      departureStartDate: "Fecha inicial de Salida",
      departureEndDate: "Fecha final de Salida",
      orderDate: "Fecha de Orden",
      shipmentDate: "Fecha de envío",
      flightDepartureDate: "Fecha de Vuelo",
      departureDate: "Fecha de Salida",
      masterboxDate: "Fecha de la caja maestra",
      overpackDate: "Fecha de Overpack",
      overpackSendDate: "Fecha de envío del Overpack",
    },
    downloadFields: {
      weight: "Peso",
      height: "Alto",
      width: "Ancho",
      length: "Largo",
      reference: "Referencia",
      lastMileTrackingNumber: "Número de seguimiento de la última milla",
      creationDate: "Fecha de Creación",
      volumeProductsValue: "Valor de los productos del volumen",
      volumeFreightValue: "Valor de flete de volumen",
      volumeDeclaredValue: "Valor declarado del volumen",
      volumeDutiesTaxValue: "Valor del Impuesto sobre Volumen",
      volumeSalesTaxValue: "Valor del impuesto sobre ventas por volumen",
      volumeTaxValue: "Valor del impuesto de volumen",
      volumeTotalValue: "Valor total del volumen",
      volumeEstimatedDutiesTaxValue: "Valor de Impuesto Estimado de Volumen",
      volumeEstimatedSalesTaxValue:
        "Valor estimado del impuesto sobre ventas por volumen",
      volumeEstimatedTaxValue: "Valor de impuesto estimado de volumen",
      volumeFreightCost: "Costo de flete por volumen",
      volumeEstimatedFreightCost: "Costo de Flete Estimado por Volumen",
      hSCode: "HS Code",
      description: "Descripción",
      currentStatus: "Status actual",
      orderID: "Pedido ID",
      sellerID: "Vendedor ID",
      contractID: "Contrato ID",
      customerName: "Nombre del Cliente",
      cPostalCode: "C. Código Postal",
      customerCity: "Ciudad del Cliente",
      customerState: "Estado del Cliente",
      customerAddress: "Dirección del Cliente",
      customerAddressNumber: "Número de Dirección del Cliente",
      customerAddressComplement: "Complemento de Dirección del Cliente",
      customerAddressReference: "Referencia de Dirección del Cliente",
      customerAddressQuarter: "Barrio de la Dirección del Cliente",
      customerDocumentType: "Tipo de Documento del Cliente",
      customerDocumentNumber: "Número de Documento del Cliente",
      customerCountry: "País del Cliente",
      customerEmail: "Correo electrónico del Cliente",
      customerPhone: "Teléfono del Cliente",
      currency: "Divisa",
      freightCost: "Costo del Flete",
      freigtValue: "Freigt Value",
      estimatedDeliveryDate: "Fecha de Entrega Estimada",
      incoterm: "Términos Internacionales de Comercio",
      orderNumber: "Número de Pedido",
      salesChannel: "Canal de Ventas",
      salesChOrderNumber: "N° Pedido del Canal de Ventas",
      totalValue: "Valor Total",
      orderReference: "Referencia del Pedido",
      financialStatus: "Status Financiero",
      overpackID: "Paquete ID",
      overpackSentDate: "Fecha de envío del Paquete",
      overpackReference: "Referencia del Overpack",
      fMTrackingNumber: "PM Número de Seguimiento",
      firstMileCompany: "Compañía de la Primera Milla",
      masterboxID: "Masterbox ID",
      dispatchID: "ID de despacho",
      cN35: "CN35",
      shipmentID: "ID de la remesa",
      shipmentCategory: "Categoría de Envío",
      cN38: "CN38",
      flightID: "Vuelo ID",
      flightN: "Vuelo Nº",
      mAWBLabel: "MAWB Label",
      flightReference: "Referencia del Vuelo",
      airline: "Compañía Aérea",
      greenLight: "Green Light",
      prealertsSent: "Prealertas enviadas",
      departureAirport: "Aeropuerto de Salida",
      arrivalAirport: "Aeropuerto de Llegada",
      departureStartDate: "Fecha Inicial de Salida",
    },
    financialStatuses: {
      nNew: "Nuevo",
      toInvoice: "Facturar",
      invoiced: "Facturado",
      canceled: "Cancelado",
      notCharged: "No Cobrado",
    },
  },
  volumesViewPage: {
    copyReference: "Copiar referencia",
    goToOrder: "Ir a ordenar",
    summaryValues: "Resumen de valores",
    trackingHistory: "Historial de seguimiento",
    warningOnUpdateLastMileTrackingNumber:
      "¡Atención! Esta acción no se puede deshacer.",
    title: "Volumen #",
    label: "Etiqueta",
    cubed_weight: "Peso Cubado",
    packingSlip: "Guía de Remesa",
    commercialInvoice: "Factura Comercial",
    valuesSummary: "RESUMEN DE VALORES",
    updatedWeight: "Updated Weight",
    items: "Artículos",
    trackinghistory: "Historial de Seguimiento",
    pages: "Páginas",
    item: "Artículo",
    height: "Alto",
    heightbycurrier: "Alto por la Transportadora",
    width: "Ancho",
    widthbycurrier: "Ancho por la Transportadora",
    weight: "Peso",
    weightbycurrier: "Peso por la Transportadora",
    length: "Largo",
    lengthbycurrier: "Largo por la Transportadora",
    orderID: "Pedido ID",
    creationDate: "Fecha de Creación",
    currentStatus: "Status actual",
    reference: "Referencia",
    lastMileCarrierNumber: "Número de la transportadora de la última milla",
    lastMileTrackingNumber: "Número de seguimiento de la última milla",
    declaredValue: "Valor declarado",
    taxValue: "Valor Fiscal",
    total: "Total",
    estimatedTaxValue: "Valor fiscal estimado",
    freightCost: "Costo del Frete",
    estimatedFreightCost: "Costo estimado de flete",
    name: "Nombre",
    value: "Valor",
    hsCode: "Hs Code",
    quantity: "Cantidad",
    originCountry: "País de Origen",
    decsription: "Descripción",
    valueWithTax: "Valor con Impuesto",
    fields: {
      name: "Nombre",
      quantity: "Cantidad",
      hsCode: "Hs Code",
      originCountry: "País de Origen",
      productValue: "Valor del Producto",
      freightValue: "Valor del flete",
      taxValue: "Valor Fiscal",
    },
    checkpointFields: {
      title: "Título",
      statusCode: "Código de Status",
      dateISO: "Fecha ISO",
      message: "Mensaje",
      trackingFunction: "Función de Seguimiento",
    },
    dirNumber: "Número DIR",
    seePayments: "Ver pagos",
  },
  carriersPage: {
    title: "Gestión de Transportistas",
    subtitle:
      "Agregue y gestione sus transportistas y sus respectivos métodos de entrega.",
    legalName: "Nombre Legal",
    rfbCode: "Código RFB",
    country: "País",
    carrierIsSeller: "Este carrier también es seller",
    sellerReminder: "No olvides cambiar la información del seller",
    importCarrier: "Importar Transportista desde JSON",
    downloadCarrierSettings:
      "Descargar Configuraciones de Transportista, Métodos de Entrega e Usuarios",
    addNewCarrier: "Agregar Nuevo Transportista",
    addCarrier: "Agregar Transportista",
    sellers: "Vendedores",
    sellerSelect: "Completar con datos del vendedor",
    allowed: "Permitido",
    blocked: "Bloqueado",
    addnewCarrier: "Agregar Nuevo Transportista",
    editCarrier: "Editar Transportista",
    name: "Nombre",
    address: "Dirección",
    addressnumber: "Número de Dirección",
    zipcode: "Código Postal",
    state: "Estado",
    city: "Ciudad",
    phone: "Teléfono",
    site: "Sitio Web",
    email: "Correo Electrónico",
    taxnumber: "Número de Identificación Fiscal",
    namereference: "Nombre de Referencia",
    slug: "Slug",
    trackstatusfunction: "Función de Seguimiento de Estado",
    carrierCode: "Código de Transportista",
    addressComplement: "Complemento de Dirección",
    airportHub: "Hub de Aeropuerto",
    fields: {
      name: "Nombre",
      slug: "Slug",
      city: "Ciudad",
      country: "País",
      email: "Correo Electrónico",
      carrierCode: "Código de Transportista",
      trackFunction: "Función de Seguimiento",
      creationDate: "Fecha de Creación",
      isSeller: "Es un vendedor?",
    },
    generalData: "Datos Generales",
    addCarrierDetails: "Agregar detalles del transportista",
    carrierAddress: "Dirección del transportista",
    addCarrierOriginAddress: "Agregar dirección de origen del transportista",
    newCarrier: "Nuevo transportista",
    addDeliveryMethod: "Agregar método de entrega",
    noDeliveryMethodFound: "No se encontraron métodos de entrega",
    noUserFound: "No se encontraron usuarios",
    deliveryMethods: "Métodos de Entrega",
    users: "Usuarios",
    carriers: "Transportadoras",
    deliveryMethodFields: {
      name: "Métodos Agregados",
      type: "Tipo",
      service: "Servicio",
      originCountry: "País de Origen",
      destinationCountry: "País de Destino",
    },
    userFields: {
      name: "Nombre",
      email: "Correo Electrónico",
      groupName: "Nombre del Grupo",
    },
    seeMethod: "Ver Método",
    seeUser: "Ver Usuario",
    airportHubHelpText:
      "El código IATA de 3 letras del aeropuerto desde el cual la aerolínea está operando",
    rfbCodeHelpText:
      "Identificador de la aerolínea en la Receita Federal Brasileña",
    isSellerHelpText:
      "Indica que este negocio también está actuando como vendedor",
    importFromJsonHelpText:
      "Esta sección te permite recrear un vendedor desde otro entorno. Ejemplo: has creado un vendedor en el entorno de homologación y te gustaría crear el mismo vendedor en el entorno de producción. En ese caso, solo necesitas hacer clic en 'Descargar Configuración de Vendedor y Usuario' en la página del vendedor en el entorno de homologación e importar el archivo json descargado aquí.",
    fillWithSellerDataHelpText:
      "Puedes prellenar la información con un vendedor que ya has creado. Solo elige el vendedor y haz clic en 'Rellenar'. No te preocupes, podrás cambiar algunos campos antes de finalizar la creación.",
    fillWithSellerData: "Rellenar con datos del vendedor",
  },
  carriersViewPage: {
    title: "Transportadora {name}",
    deliverymethods: "Métodos de Entrega",
    nodeliverymethodfoundfor: "No se encontró ningún método de entrega para",
    users: "Usuarios",
    nouserfoundfor: "No se encontró ningún usuario para",
    editCarrier: "Editar Transportadora",
    updateAirportHub: "Actualizar hub del Aeropuerto",
    airportHub: "Hub del Aeropuerto",
    name: "Nombre",
    address: "Dirección",
    addressnumber: "Número de Dirección",
    addresscomplement: "Complemento de Dirección",
    zipcode: "CP",
    state: "Estado",
    city: "Ciudad",
    phone: "Teléfono",
    site: "Página Web",
    email: "Correo electrónico",
    taxnumber: "Número de identificación fiscal",
    namereference: "Nombre de Referencia",
    slug: "Slug",
    trackstatusfunction: "Función de seguimiento de status",
    carrierCode: "Código de la Transportadora",
    country: "País",
    taxNumber: "Número de identificación fiscal",
    trackingfunction: "Función de Seguimiento",
    platform: "Plataforma",
    subsidiary: "Subsidiaria",
    deliveryCenter: "Centro de Entrega",
    createdDate: "Fecha de Creación",
    activityTypes: "Tipos de Actividad",
    deliveryMethodFields: {
      name: "Nombre",
      type: "Tipo",
      service: "Servicio",
      originCountry: "País de Origen",
      destinationCountry: "País de Destino",
    },
    userFields: {
      name: "Nombre",
      email: "Correo electrónico",
      groupName: "Nombre del Grupo",
    },
    seller: "Vendedor",
    thisCarrierIsSeller: "Este transportadora es un vendedor.",
    addDeliveryMethodToThisCarrier:
      "Agregar Método de Entrega a esta Transportista",
    addUserToThisCarrier: "Agregar usuario a esta Transportista",
  },
  groupsPage: {
    title: "Gestión de Grupos",
    fields: {
      name: "Nombre",
    },
  },
  groupsViewPage: {
    title: "Grupo #",
    permissions: "Permisos",
  },
  permissionsPage: {
    title: "Permisos",
    fields: {
      name: "Nombre",
      description: "Descripción",
      groupsName: "Nombre de los Grupos",
    },
  },
  permissionsViewPage: {
    title: "Permiso #",
    name: "Nombre",
    description: "Descripción",
  },
  saldoViewPage: {
    currentBalance: "Saldo Actual",
    events: "Eventos",
    fields: {
      category: "Categoría",
      eventType: "Evento",
      value: "Valor",
      description: "Descripción",
      paid: "Pagado",
    },
  },
  sellersPage: {
    title: "Gestión de Vendedores",
    carriers: "Transportistas",
    carrierSelect: "Completar con datos del transportista",
    legalName: "Nombre Legal",
    rfbCode: "Código de RFB",
    addNewSeller: "Agregar Nuevo Vendedor",
    importSeller: "Importar Vendedor desde JSON",
    downloadSellers: "Descargar Vendedores",
    processingDownloads:
      "Revisa las descargas de procesamiento en... página de descargas",
    addseller: "Agregar Vendedor",
    address: "Dirección",
    addressnumber: "Número de Dirección",
    addresscomplement: "Complemento de Dirección",
    city: "Ciudad",
    state: "Estado",
    postalcode: "Código Postal",
    name: "Nombre",
    phone: "Teléfono",
    email: "Correo electrónico",
    site: "Página Web",
    taxnumber: "Número de identificación fiscal",
    signature: "Firma",
    billingEmail: "Correo electrónico de Cargo",
    responsableContact: "Contacto Responsable",
    allowedcontracts: "Contratos Permitidos",
    contractOperations: "Operaciones de contrato",
    issubsidiary: "Es Subsidiaria",
    isdistributioncenter: "Es centro de distribución",
    ismultiple: "Es multiple",
    country: "País",
    fields: {
      name: "Nombre",
      email: "Correo electrónico",
      city: "Ciudad",
      ismultiple: "es multiple",
      country: "País",
      site: "Página Web",
      billingEmail: "Correo electrónico de Facturación",
      responsableContact: "Contacto Responsable",
      creationDate: "Fecha de Creación",
      currentBalance: "Saldo Actual",
      isCarrier: "Es un transportador?",
      zipcode: "Código Postal",
    },
    multiples: "Multiples",
    simples: "Simples",
    importSellerJsonPopup: `Esta sección le permite volver a crear un vendedor desde otro entorno. Ejemplo: ha creado un vendedor en un entorno homolog y desea crear exactamente el mismo vendedor en un entorno de producción. En ese caso, solo tiene que hacer clic en "Descargar configuración de vendedor y usuario" en la página del vendedor en el entorno homolog e importar aquí el archivo json descargado.`,
    balance: "Saldo",
    fillWithCarrierPopup: `Puedes rellenar previamente la información con un transportista que ya hayas creado. Solo tienes que elegir el transportista y hacer clic en "Rellenar". No te preocupes, podrás cambiar algunos campos antes de finalizar la creación.`,
    contractsPopup: `Ya puedes permitir algunos contactos a este vendedor. Si no deseas hacerlo, ¡no te preocupes! Podrás hacerlo en cualquier momento más adelante.`,
    startValidityDate: "Data de validade inicial",
    endValidityDate: "Data de validade final",
    languagePreferred: "Idioma preferido",
    measurementSystem: "Sistema de medición",
    validateCpf: "Validar los CPFs de los clientes en todos los pedidos",
    seeSellerVolumes: "Ver volúmenes del vendedor",
    seeSellerOrders: "Ver pedidos del vendedor",
  },
  sellersViewPage: {
    chooseSendRequest: "Por favor, elige a quién le enviarás la solicitud:",
    downloadSellerSettings: "Descargar Configuraciones de Vendedor e Usuarios",
    legalName: "Nombre Legal",
    rfbCode: "Código de RFB",
    website: "Página Web",
    sellerIsCarrier: "Este seller también es carrier",
    carrierReminder: "No olvides cambiar la información del carrier",
    taxNumber: "Número de identificación fiscal",
    signature: "Firma",
    isSubsidiary: "Es Subsidiaria",
    isDistributionCenter: "Es centro de distribución",
    sellerID: "Vendedor ID",
    currentBalance: "Saldo Actual",
    seller: "Vendedor",
    name: "Nombre",
    email: "Correo electrónico",
    phone: "Teléfono",
    ismultiple: "es multiple",
    allowedContracts: "Contratos Permitidos",
    contractOperations: "Operaciones de contrato",
    noContractFound: "No se encontró ningún contrato",
    creationDate: "Fecha de Creación",
    contractLink: "Link del Contrato",
    notFound: "No encontrado",
    billingEmail: "Correo electrónico de Facturación",
    responsableContact: "Contacto Responsable",
    platform: "Plataforma",
    address: "Dirección",
    country: "País",
    postalCode: "Código Postal",
    state: "Estado",
    devolutionAddress: "Dirección de devolución",
    addressNumber: "Número de Dirección",
    addressQuarter: "Barrio de Dirección",
    addressComplement: "Complemento de Dirección",
    addressReference: "Referencia de Dirección",
    city: "Ciudad",
    devolutionEmail: "Correo electrónico de Devolución",
    useSellerDevolutionAddress:
      "Utiliza la dirección de devolución del vendedor",
    users: "Usuarios ",
    active: "Activo",
    blocked: "Bloqueado",
    clear: "Limpiar",
    noUserFoundFor: "No se encontró ningún usuario para",
    includeLogo: "Incluir logo",
    wantAllowContract: "Quieres permitir el contrato",
    forSeller: "para el vendedor",
    needsSupervisorApproval:
      "Esta acción necesita de la aprobación de un supervisor",
    wantForbidContract: "Quieres prohibir el contrato",
    fields: {
      fullName: "Nombre Completo",
      email: "Correo electrónico",
      status: "Status",
    },
    carrier: "Transportador",
    thisSellerIsCarrier: "Este vendedor es un transportadora.",
    accessWebsite: "Acceder al sitio web",
    understandCurrentBalance: "Comprender el saldo actual",
  },
  usersPage: {
    title: "Users",
    subtitle: "Verificar y agregar nuevos usuarios al sistema",
    personalData: "Datos personales",
    seller: "Vendedor",
    carrier: "Transportadora",
    downloadUsers: "Descargar Usuarios",
    allowed: "Permitido",
    blocked: "Bloqueado",
    denerateAPIToken: "Generar Token de la API",
    processingDownloads:
      "Revisa las descargas de procesamiento en... página de descargas",
    allUsers: "Todos los usuarios",
    addNewUser: "Agregar Nuevo Usuario",
    name: "Nombre",
    email: "Correo electrónico",
    phone: "Teléfono",
    whatsappNumber: "Número de Whatsapp",
    profile: "Perfil",
    group: "Grupo",
    selectGroup: "Selecciona el grupo",
    aPIToken: "Token de la API",
    permissionsAndAccess: "Permisos y acceso",
    userStatus: "Estatus de usuario",
    editUser: "Editar usuario",
    fields: {
      name: "Nombre",
      carrierID: "Transportadora",
      sellerID: "Vendedor",
      company: "Empresa",
      status: "Status",
      email: "Correo electrónico",
      profile: "Perfil",
      group: "Grupo",
      creationDate: "Fecha de Creación",
      aPIToken: "Token de la API",
      supervisor: "Supervisor",
    },
    insertUserData: "Inserta los datos del usuario que deseas crear.",
    archiveUser: "¿Archivar usuário?",
    confirmArchiveUser:
      "¿Está seguro de que desea archivar al usuario? Esto no se puede deshacer.",
    yeahArchiveUser: "Sí, usuario de archivo",
    confirmBlockUser:
      "Este usuario perderá inmediatamente el acceso al sitio y a la API. Esto se puede deshacer en cualquier momento.",
    confirmUnblockUser:
      "A este usuario se le concederá acceso inmediato al sitio y a la API. Esta acción se puede deshacer en cualquier momento.",
    confirmRemoveUser:
      "¿Realmente desea eliminar este usuario? Esta acción no se puede deshacer",

    profileHelp:
      "Los usuarios administradores tendrán acceso a este sitio y gestionarán cuentas, vuelos, logs, datos fiscales... Los usuarios vendedores tendrán acceso al sitio del vendedor y crearán pedidos y generarán etiquetas. Los usuarios transportistas gestionarán masterboxes y vuelos.",
    groupHelp:
      "El administrador tiene los permisos máximos en la cuenta y puede realizar cualquier acción en los envíos o usuarios, incluida la eliminación, creación o bloqueo de otros usuarios. La operación tiene básicamente los mismos permisos, a menos que se trate de crear, bloquear o eliminar otros usuarios. El espectador solo puede ver los datos, pero no puede crear, actualizar ni eliminar nada.",
  },
  usersViewPage: {
    title: "Perfil de Usuario",
    blocked: "Bloqueado",
    allowed: "Permitido",
    blockUser: "Bloquear a este usuario",
    unblockUser: "Desbloquear a este usuario",
    company: "Empresa",
    editUserProfile: "Editar Perfil de Usuario",
    name: "Nombre",
    email: "Correo electrónico",
    phone: "Teléfono",
    whatsappNumber: "Número de Whatsapp",
    group: "Grupo",
    selectgroup: "Selecciona el grupo",
    profile: "Perfil",
    seller: "Vendedor",
    carrier: "Transportadora",
    phoneNumber: "Número de Teléfono",
    createdDate: "Fecha de Creación",
    sellerID: "Vendedor ID",
    carrierID: "Transportadora ID",
    platform: "Plataforma",
  },
  dutiesAndTaxesStatusPage: {
    title: "Estado de derechos e impuestos",
    subTitle:
      "En esta página, puedes ver los pagos de impuestos realizados en tus pedidos y extraer los datos como archivos CSV",
    importValuesandStatuses: "Importar Valores y Status",
    updateStatus: "Actualizar Status",
    download: "Descargar",
    new: "Nuevo",
    solved: "Resuelto",
    priority: "Prioridad",
    inProgress: "En Progreso",
    waitingforPayment: "Esperando el Pago",
    sentAgain: "Enviado Nuevamente",
    paymentDone: "Pago Efectuado",
    pending: "Pendiente",
    paidExternally: "Pago Externamente",
    needSellerAction: "Necesita de Acción del Vendedor",
    status: "Status",
    comment: "Comentario",
    checktheprocessingdownloads:
      "Revisa las descargas de procesamiento en... página de descargas",
    sureWantSave: "Estás seguro de que deseas guardar los cambios",
    newTrackingNumbers: "Nuevo número de seguimiento",
    shouldbeCommaSeparated:
      "Los nuevos números deben separarse por coma y seguir la secuencia de Tracking Alert Logs seleccionados: TAL_IDs:",
    unsaveddata: "Datos no Guardados",
    pleasesaveunsaveddata: "Guarda los datos no guardados o cancele",
    okay: "OK",
    name: "Nombre",
    browseFilesUpload: "Explora tus archivos y carga tus archivos",
    selectcsvFileUpload: "Selecciona un archivo csv para cargar",
    downloadHere: "Descarga Aquí",
    instructionsXLSfile: "El archivo XLS de instrucciones",
    fileUploaded: "Archivo enviado",
    blankTable: "un archivo en blanco",
    fields: {
      id: "NB Referencia de Pago Interno",
      sellerName: "Nombre del Vendedor",
      sellerId: "ID del Vendedor",

      orderNumber: "Número de Orden",
      orderCreatedAt: "Fecha de Creación del Pedido",
      orderCreatedAtGte: "Inicio de Fecha de Creación del Pedido",
      orderCreatedAtLte: "Fim de Fecha de Creación del Pedido",

      volumeId: "ID del Volumen",
      volumeLastMileTrackingNumber: "Número de Seguimiento Última Milla",
      orderEstimatedTaxValue: "Derechos e impuestos totales estimados",
      orderEstimatedTaxValueGte:
        "Inicio de Derechos e impuestos totales estimados",
      orderEstimatedTaxValueLte:
        "Fim de Derechos e impuestos totales estimados",
      orderEstimatedDutiesTaxValue: "Impuestos Estimados II",
      orderEstimatedDutiesTaxValueGte: "Inicio de Impuestos Estimados II",
      orderEstimatedDutiesTaxValueLte: "Fim de Impuestos Estimados II",
      orderEstimatedSalesTaxValue: "Impuestos Estimados ICMS",
      orderEstimatedSalesTaxValueGte: "Inicio de Impuestos Estimados ICMS",
      orderEstimatedSalesTaxValueLte: "Fim de Impuestos Estimados ICMS",

      currentStatusId: "Seguimiento",
      currentStatusTitle: "Estado de Seguimiento",
      currentStatusTrackingCode: "Código de Seguimiento",
      currentStatusTrackingCodeGte: "Inicio de Código de Seguimiento",
      currentStatusTrackingCodeLte: "Fim de Código de Seguimiento",

      createdAt: "Fecha de inicio de aduanas",
      createdAtGte: "Inicio de Fecha de inicio de aduanas",
      createdAtLte: "Fim de Fecha de inicio de aduanas",

      statusPaymentDoneAt: "Fecha de pago",
      statusPaymentDoneAtGte: "Inicio de Fecha de pago",
      statusPaymentDoneAtLte: "Fim de Fecha de pago",

      status: "Estado de pago",
      paymentCertificate: "Certificado de Pago",

      realTax: "Total de Impuestos y Tasas Pagados",
      realTaxGte: "Inicio de Total de Impuestos y Tasas Pagados",
      realTaxLte: "Fim de Total de Impuestos y Tasas Pagados",

      exchangeRate: "Tipo de Cambio",
      seller: "Vendedor",
    },
  },
  dutiesAndTaxesStatusViewPage: {
    title: "Estado de derechos e impuestos #",
    sellerChargedAmount: "Valor cobrado por el vendedor",
    exchange: "Cambio",
    incoterm: "Términos Internacionales de Comercio",
    date: "Fecha",
    dateIso: "Fecha Iso",
    orderID: "Pedido ID",
    volumeID: "Volumen ID",
    sellerID: "Vendedor ID",
    taxID: "Número de Identificación Fiscal",
    lastMileNumber: "Número de la última milla",
    productsValue: "Valor de los Productos",
    freightValue: "Valor del flete",
    expectedTax: "Impuesto esperado",
    valueR$: "Valor R$",

    id: "NB Referencia de Pago Interno",
    sellerName: "Nombre del Vendedor",
    sellerId: "ID del Vendedor",

    orderNumber: "Número de Orden",
    orderCreatedAt: "Fecha de Creación del Pedido",
    orderCreatedAtGte: "Inicio de Fecha de Creación del Pedido",
    orderCreatedAtLte: "Fim de Fecha de Creación del Pedido",

    volumeId: "ID del Volumen",
    volumeLastMileTrackingNumber: "Número de Seguimiento Última Milla",
    orderEstimatedTaxValue: "Derechos e impuestos totales estimados",
    orderEstimatedTaxValueGte:
      "Inicio de Derechos e impuestos totales estimados",
    orderEstimatedTaxValueLte: "Fim de Derechos e impuestos totales estimados",
    orderEstimatedDutiesTaxValue: "Impuestos Estimados II",
    orderEstimatedDutiesTaxValueGte: "Inicio de Impuestos Estimados II",
    orderEstimatedDutiesTaxValueLte: "Fim de Impuestos Estimados II",
    orderEstimatedSalesTaxValue: "Impuestos Estimados ICMS",
    orderEstimatedSalesTaxValueGte: "Inicio de Impuestos Estimados ICMS",
    orderEstimatedSalesTaxValueLte: "Fim de Impuestos Estimados ICMS",

    currentStatusId: "Seguimiento",
    currentStatusTitle: "Estado de Seguimiento",
    currentStatusTrackingCode: "Código de Seguimiento",
    currentStatusTrackingCodeGte: "Inicio de Código de Seguimiento",
    currentStatusTrackingCodeLte: "Fim de Código de Seguimiento",

    createdAt: "Fecha de inicio de aduanas",
    createdAtGte: "Inicio de Fecha de inicio de aduanas",
    createdAtLte: "Fim de Fecha de inicio de aduanas",

    statusPaymentDoneAt: "Fecha de pago",
    statusPaymentDoneAtGte: "Inicio de Fecha de pago",
    statusPaymentDoneAtLte: "Fim de Fecha de pago",

    status: "Estado de pago",
    paymentCertificate: "Certificado de Pago",

    realTax: "Total de Impuestos y Tasas Pagados",
    realTaxGte: "Inicio de Total de Impuestos y Tasas Pagados",
    realTaxLte: "Fim de Total de Impuestos y Tasas Pagados",

    exchangeRate: "Tipo de Cambio",
  },

  accessibilitySettingsPage: {
    subtitle: "Configura la tipografía, preferencias, idioma y más.",
    typography: "Tipografía",
    textPreferenceDescription:
      "Configura tus preferencias de texto a continuación.",
    textSize: "Tamaño del texto",
    language: "Idioma",
    mainLanguageDescription: "Define el idioma principal de la plataforma.",
    selectLanguage: "Selecciona el idioma",
    changeUnitsText: "cambiar unidades de peso y medidas",
    stillHavingDifficulties: "¿Sigue teniendo dificultades?",
    weWantToHelpYou:
      "¡Queremos ayudarte! Contáctanos de la manera que prefieras",
    contactViaPhone: "Contactar por teléfono",
    contactViaEmail: "Contactar por correo electrónico",
    contactViaChat: "Contactar por chat",
  },
  searchHsCodePage: {
    title: "Buscar HS Code",
    subtitle:
      "¿Quiere saber un código HS o si el producto está permitido? ¡Busca ahora mismo!",
    code: "Ingrese el código",
    category: "Categoría",
    noHscode: "registro no encontrado.",
    tooltip:
      "Un Código Hs o NCM es un código de 6 u 8 dígitos que identifica el tipo de producto a importar. Esta página le permite ver si un código en particular está permitido para ingresar a Brasil",
  },
  requestsPage: {
    title: "Solicitudes",
    subTitle: "Verifica y rastrea el estado de la solicitud con tu ID",
    requestId: "ID de Solicitud",
    asynchronousRequests: "Solicitudes Asíncronas",
    result: "Resultado",
  },
  shippingRulesPage: {
    title: "Reglas de Envío",
    subtitle:
      "En esta página, puedes definir reglas de envío que te permitirán aplicar algunas acciones en tus pedidos, dependiendo de algunos desencadenantes que configurarás",
    addNewRule: "Agregar Nueva Regla",
    seller: "Vendedor",
    sellers: "Vendedores",
    name: "Nombre de la Regla",
    expiration: "Expiración",
    condition: "Condición",
    conditions: "Condiciones",
    options: "Opciones",
    operator: "Operador",
    value: "Valor",
    selectMultipleSellers: "Por favor, elige uno o más vendedores",
    createConditionTitle: "Agregar Nueva Regla",
    createCondition:
      "Seleccione las condiciones que debe cumplir su envío y haga clic en Agregar condición para validar su elección. Puede seleccionar múltiples condiciones, para que la regla se aplique, todas las condiciones deben estar verificadas.",
    addCondition: "Agregar Condición",
    createActionTitle: "Acción",
    createAction:
      'En este campo elegirás qué debe suceder de acuerdo a tu condición. Por ejemplo, para la condición "Todos los pedidos" puedes seleccionar "siempre enviar desde la Ubicación X"',
    is_active: "Activo",
    is_active_helptext:
      "Si deseas crear la regla sin aplicarla por ahora, simplemente deselecciona este campo",
    freightValue: "Valor del Flete",
    priority: "Prioridad",
    destinationCountry: "País de Destino",
    destinationStates: "Estados de Destino",
    rulePriorityOptions: {
      veryHigh: "Muy Alta",
      high: "Alta",
      medium: "Media",
      low: "Baja",
      veryLow: "Muy Baja",
    },
    dateFieldHelpText:
      "Después de esta fecha, la regla ya no se aplicará, pero aún estará disponible en esta página si deseas extender la validez más adelante",
    priorityFieldHelpText:
      "En caso de conflicto entre varias reglas, la prioridad indicará qué regla se aplicará sobre la otra",
    sellersFieldHelpText:
      "Elige qué vendedores se verán afectados por la regla",
    activeFieldHelpText:
      "Si no está activa, la regla se creará pero no se aplicará hasta que la actives",
    addNewCondition: "Agregar nueva condición",
    freightValueActionDescription:
      "El valor del flete del pedido siempre se tomará del costo del flete (calculado a través de tablas de precios).",
    fields: {
      priority: "Prioridad",
      name: "Nombre",
      sellers: "Vendedores",
      expiration: "Expiración",
      condition: "Condición",
      action: "Acción",
      isActive: "¿Está Activo?",
      actions: "Acciones",
    },
  },
  fiscalPaymentsPage: {
    title: "Pagos Fiscales",
    fiscalInfo: "Información Fiscal",
    fiscalParcelData: "Datos de la Parcela Fiscal",
    customerData: "Datos del Cliente",
    fields: {
      reference_number: "Referencia",
      barcode: "Código de Barras",
      authentication: "Autenticación",
      status: "Estado",
      payment_type: "Tipo de Pago",
      value: "Valor",
      fiscal_parcel_id: "ID de Parcela Fiscal",
      payment_date: "Fecha de Pago",
      created_at: "Fecha de Creación",
      dir_number: "Número de DIR",
      customer_name: "Nombre del Cliente",
      customer_cpf: "CPF del Cliente",
      customer_cep: "CEP del Cliente",
      tracking_number: "Número de Seguimiento",
      products_description: "Descripción de los Productos",
      customer_state: "Estado del Cliente",
      seller_id: "ID del Vendedor",
      dir_date: "Fecha DIR",
      start_payment_date: "Fecha de Inicio del Pago",
      end_payment_date: "Fecha de Fin del Pago",
      start_created_at: "Fecha de Inicio de Creación",
      end_created_at: "Fecha de Fin de Creación",
      start_value: "Valor Inicial",
      end_value: "Valor Final",
      start_dir_date: "Fecha Inicial DIR",
      end_dir_date: "Fecha Final DIR",
      boletoGNRE: "Boleto GNRE",
      paymentDate: "Fecha de Pago",
      masterNumber: "Master Number",
      messages: "Mensajes",
      courrierName: "Courrier Name",
      courrierCNPJ: "Courrier CNPJ",
    },
    status: {
      processing: "Procesando",
      processed: "Procesado",
      error: "Error",
    },
  },
  notFoundPage: {
    pageNotFound: "Página no encontrada",
    goToHome: "Ir a la página de inicio",
  },
  trackingRuleNotificationsPage: {
    title: "Reglas de notificación de seguimiento",
    addConfig: "Agregar configuración",
    createRule: "Crear regla",
    giveRuleName: "Dale un nombre a tu regla",
    ruleName: "Nombre de la regla",
    applyRuleTo: "Quieres aplicar la regla a:",
    allShipments: "Todos los envíos",
    someShipmentsOnly: "Solo algunos envíos",
    chooseSellers: "Primero, elige los vendedores que usarán estas reglas",
    WhenNewSellersIsCreated:
      "¿Qué quieres hacer cuando se cree un nuevo vendedor?",
    addThisRuleAutomatically: "Agregar automáticamente a esta regla",
    addManually:
      "No lo agregues a esta regla, se agregarán manualmente si es necesario",
    notifications: "Notificaciones",
    conditions: "Condiciones",
    wouldYouLikeToRemoveRule: "¿Te gustaría eliminar la regla?",
    wouldYouLikeToDisableRule: "¿Te gustaría desactivar la regla?",
    wouldYouLikeToEnableRule: "¿Te gustaría habilitar la regla?",
    removalIsFinal: "La eliminación es definitiva",
    disableRuleMessage:
      "La regla se guardará en el sistema, pero no se aplicará a ningún pedido, con efecto inmediato. Esto se puede deshacer en cualquier momento.",
    enableRuleMessage:
      "La regla se aplicará a las solicitudes involucradas con efecto inmediato. Esto se puede deshacer en cualquier momento.",
    sellersPermission: "Permiso de vendedores",
    sellersThatCanConfigureTheirOwnRules:
      "Vendedores que pueden configurar sus propias reglas",
    sellersThatCanConfigureTheirOwnRulesDescription:
      "Para estos vendedores, ninguna de las reglas creadas aquí se aplicará y podrán configurar las suyas propias",
    sellersThatWillUseRulesConfiguredHere:
      "Vendedores que usarán las reglas configuradas aquí",
    sellersThatWillUseRulesConfiguredHereDescription:
      "Estos vendedores no podrán configurar sus propias reglas",
    createOrUseExistingRule: "¿Te gustaría crear o usar una regla existente?",
    createFromScratch: "Crear desde cero",
    useExistingRule: "Usar una regla existente como preconfiguración",
    selectRuleToUse: "Selecciona la regla que deseas usar",
    defineNotificationRules:
      "Define reglas de notificación para grupos de checkpoints",
    allSelected: "Todos seleccionados",
    selectAll: "Seleccionar todo",
    onSellerCreation: "¿Qué hacer cuando se cree un nuevo vendedor?",
    onContractCreation: "¿Qué hacer cuando se cree un nuevo contrato?",
    toEditSellersList: "Para editar la lista de vendedores, necesitas elegir:",
    toEditContractsList: "Para editar la lista de contratos, necesitas elegir:",
    autoAddRule: "Agregar automáticamente a esta regla",
    notAddThisRule:
      "No lo agregues a esta regla, se agregarán manualmente si es necesario",
    searchByNameOrId: "Buscar: encontrar por nombre o id",
    noItemsSelected: "No hay elementos seleccionados",
    selectedItems: "Elementos seleccionados",
    chooseContracts:
      "Ahora, elige a cuáles de los contratos a continuación deseas aplicar la regla",
    allShipmentWarning:
      "Estás a punto de crear una regla que se aplicará a todos los envíos",
    keepEditing: "Seguir editando",
    goIt: "Entendido",
    wantToCreateRule: "¿Quieres crear la regla configurada?",
    totalSellersLinked: "Número total de vendedores vinculados a esta regla",
    totalContractsLinked: "Número total de contratos vinculados a esta regla",
    allSellersIncluded:
      "Todos los vendedores han sido incluidos en esta regla, incluidos los nuevos vendedores que se agregarán más tarde.",
    allContractsIncluded:
      "Todos los contratos han sido incluidos en esta regla, incluidos los nuevos contratos que se agregarán más tarde.",
    almostThere: "Casi allí..",
    ruleCreatedSuccessfully: "¡Regla creada con éxito!",
    youWantActivate:
      "Está deshabilitado por ahora. ¿Quieres habilitar esta regla ahora?",
    maybeLater: "Quizás más tarde",
    notifsConfigured: "{total} notificaciones configuradas",
    allSellers: "Todos los vendedores",
    allContracts: "Todos los contratos",
    totalSellers: "{total} vendedores",
    totalContracts: "{total} contratos",
    updateRule: "Actualizar regla",
    wantToUpdateRule: "¿Quieres actualizar la regla configurada?",
    ruleUpdatedSuccessfully: "¡Regla actualizada con éxito!",
    activateUpdatedRule:
      "La regla se ha actualizado correctamente y se ha desactivado por razones de seguridad. ¿Quieres activar la regla ahora?",
    noSellerSelected: "Ninguna vendedor seleccionada",
    noContractSelected: "No se ha seleccionado ningún contrato",
    sellersSelected: "vendedores seleccionado",
    contractsSelected: "contratos seleccionado",
    youDontHaveSeller:
      "Aún no tienes ningún vendedor. Para configurar reglas, primero debes crear tu primer vendedor",
    youDontHaveContractAllowed:
      "Estos vendedores aún no tienen ningún contrato. Primero debes permitir algunos contratos a estos vendedores",
    goToSellersPage: "Ir a la página de vendedores",
    gotToContractsPage: "Ir a la página de contratos",
    ruleNameAlreadyExists: "El nombre de la regla proporcionada ya existe",
  },
};
