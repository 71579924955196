var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NbPageTitle',{staticClass:"mb-4",attrs:{"title":_vm.$t('downloadsPage.title'),"subTitle":_vm.$t('downloadsPage.subtitle')}}),_c('NbTablev2',{ref:"downloadsPage",staticClass:"mt-5",attrs:{"tableOf":"downloadsPage","hasParentItens":true,"hiddeTableOptions":true,"height":"fit-content","allFields":_vm.allFields,"fields":_vm.fields,"startItems":_vm.downloads},on:{"reloadFields":function($event){_vm.fields = $event}},scopedSlots:_vm._u([{key:"cell(file_type)",fn:function(data){return [(data.item.data_type == 'order_import')?_c('span',[_vm._v("Upload")]):_c('span',[_vm._v("Download")])]}},{key:"cell(status)",fn:function(data){return [_c('div',[_c('NbBadge',{staticClass:"fit-content",attrs:{"type":_vm.badgeType(data.item.status),"text":_vm.badgeText(data.item.status)}})],1)]}},{key:"cell(file_name)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.file_name ? data.item.file_name : "-"))])]}},{key:"cell(errors)",fn:function(data){return [(
          data.item.df_errors.length > 0 &&
          !data.item.file_name.includes('volumes_mass_update')
        )?_c('div',{staticClass:"link-1",attrs:{"variant":"danger","title":"Show Errors","data-toggle":"modal","data-target":"#showErrors"},on:{"click":function($event){_vm.currentErrors = data.item.df_errors}}},[_vm._v(" "+_vm._s(_vm.$t("downloadsPage.showErrors"))+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [(
          data.item.status == 'failed' &&
          data.item.file_name.includes('volumes_mass_update')
        )?_c('NbButton',{staticClass:"mb-2",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.downloadFiles(data.item.id)}}},[_c('NbIcon',{attrs:{"icon":"arrow-down"}}),_vm._v(" "+_vm._s(_vm.$t("downloadsPage.downloadReport")))],1):(
          !(data.item.data_type == 'volumes_mass_update') &&
          data.item.status == 'done'
        )?_c('NbButton',{staticClass:"mb-2",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.downloadFiles(data.item.id)}}},[_c('NbIcon',{attrs:{"icon":"arrow-down"}}),_vm._v(" "+_vm._s(_vm.$t("downloadsPage.downloadReport")))],1):_vm._e()]}}])}),_c('NbModal',{attrs:{"id":"showErrors","modalConfig":"modal-dialog-centered","width":"46rem"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex mb-4"},[_c('h5',{staticClass:"heading-4 ml-3 mb-1"},[_vm._v(_vm._s(_vm.$t("downloadsPage.errors")))])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('NbButton',{attrs:{"variant":"secondary","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")])],1)]},proxy:true}])},[_c('div',_vm._l((_vm.currentErrors),function(currentError){return _c('div',{key:currentError,staticClass:"border-bottom pb-2 mb-2"},[_vm._v(" - "+_vm._s(currentError)+" ")])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }