<template>
  <NbModal
    id="modalCurrentPass"
    modalConfig="modal-dialog-centered"
    :preventClose="true"
    width="500px"
  >
    <template v-slot:header>
      <div class="heading-4">
        {{ $t("userProfilePage.currentPass") }}
      </div>
    </template>
    <template v-slot:body>
      <p>{{ $t("userProfilePage.confirmCurrentPassword") }}</p>
      <NbPassInput
        required
        id="reset-email"
        :name="$t('userProfilePage.currentPass')"
        class="mb-2"
        autocomplete="off"
        :placeholder="$t('userProfilePage.currentPass')"
        :error="[error]"
        v-model="modelValue"
      />
    </template>
    <template v-slot:footer>
      <NbButton
        class="float-right mr-3"
        @click="handleSubmit"
        :disabled="loading || !modelValue"
        >{{ $t("save") }}</NbButton
      >
      <NbButton
        variant="secondary"
        class="float-right mr-2"
        @click="closeModal()"
        >{{ $t("cancel") }}</NbButton
      >
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbPassInput from "@/components/input/text/NbPassInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  components: { NbModal, NbPassInput, NbButton },
  props: {
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("submit");
    },
    closeModal() {
      this.$helpers.closeModal("modalCurrentPass");
    },
    showModal() {
      this.$helpers.openModal("modalCurrentPass");
    },
  },
};
</script>

<style lang="scss" scoped></style>
