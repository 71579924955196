<template>
  <NbModal
    id="modalSendNotification"
    modalConfig="modal-dialog-centered"
    :preventClose="true"
    width="800px"
  >
    <template #header>
      <div>
        <h4 class="heading-4 mb-0">
          {{ $t("notificationsPage.newNotification") }}
        </h4>
        <p class="body-4">
          {{ $t("notificationsPage.settingYourNotification") }}
        </p>
      </div>
    </template>

    <template #body>
      <NbCard
        id="general-data"
        :title="$t('notificationsPage.generalData')"
        :subTitle="$t('notificationsPage.settingNotificationParams')"
      >
        <template #body>
          <form>
            <div class="group">
              <NbSelectInput
                id="to"
                :name="$t('notificationsPage.to')"
                :placeholder="$t('notificationsPage.selectRecipientType')"
                v-model="formData.recipient"
                :options="recipientOptions"
                required
                :error="[errors.get('to')]"
              />
              <NbSelectInput
                id="recipient-id"
                :name="$t('notificationsPage.recipient')"
                placeholder=""
                v-model="formData.recipientId"
                :options="recipients"
                required
                :error="[errors.get('recipientId')]"
              />
            </div>

            <NbSelectInput
              :name="$t('notificationsPage.subject')"
              id="subject"
              placeholder=""
              v-model="formData.subject"
              :options="allSellerNotificationTitles"
              required
              :error="[errors.get('subject')]"
            />

            <NbTextInput
              id="message"
              v-model="formData.message"
              :name="$t('notificationsPage.message')"
              required
              :error="[errors.get('message')]"
              type="textarea"
            />
          </form>
        </template>
      </NbCard>
    </template>

    <template #footer>
      <div class="d-flex justify-content-between">
        <NbButton variant="secondary" @click="close()">{{
          $t("cancel")
        }}</NbButton>
        <NbButton @click="sendNotification" :disabled="!isValid || loading">{{
          $t("notificationsPage.sendNow")
        }}</NbButton>
      </div>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import Errors from "@/utils/Errors";

import NotificationService from "@/services/NotificationsService";
// import PendingActionService from "../../../services/PendingActionService";

const notificationService = new NotificationService();
// const pendingActionService = new PendingActionService();

export default {
  name: "ModalSendNotification",
  components: { NbModal, NbCard, NbButton, NbSelectInput, NbTextInput },
  data() {
    return {
      errors: new Errors(),
      loading: false,
      formData: {
        recipientId: "",
        recipient: "",
        subject: "",
        message: "",
      },
    };
  },
  created() {
    this.$store.dispatch("sellers/fetchAllItems", "sellers");
    this.$store.dispatch("users/fetchAllItems", "users");
  },
  computed: {
    isValid() {
      return Object.values(this.formData).every((item) => {
        if (typeof item === "number") {
          return item;
        }

        return item?.trim();
      });
    },
    recipientOptions() {
      return [
        { text: this.$t("notificationsPage.seller"), value: "seller" },
        { text: this.$t("notificationsPage.user"), value: "user" },
      ];
    },
    allSellerNotificationTitles() {
      return [
        {
          value: "finance",
          text: this.$t("notificationsPage.finance"),
        },
        {
          value: "general_issue",
          text: this.$t("notificationsPage.generalIssue"),
        },
        {
          value: "insufficient_balance",
          text: this.$t("notificationsPage.insufficientBalance"),
        },
        {
          value: "tracking_issue",
          text: this.$t("notificationsPage.trackingIssue"),
        },
      ];
    },
    users() {
      return this.$store.state.users.all_items?.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
    sellers() {
      return this.$store.state.sellers.all_items?.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
    recipients() {
      if (!this.formData.recipient) {
        return [];
      }

      if (this.formData.recipient === "user") {
        return this.sortByName(this.users);
      }

      return this.sortByName(this.sellers);
    },
  },
  methods: {
    sortByName(data = []) {
      return data.sort((a, b) => {
        if (!a.text) {
          return -1;
        }

        return a.text.trim().localeCompare(b.text.trim(), undefined, {
          sensitivity: "base",
        });
      });
    },
    addNotification() {
      if (this.recipient == "seller") {
        this.addSellerNotification();
      }
      if (this.recipient == "user") {
        this.addUserNotification();
      }
    },
    resetForm() {
      this.formData = {
        recipientId: "",
        recipient: "",
        subject: "",
        message: "",
      };
    },
    requestNotification() {
      const data = {
        body: this.formData.message,
        title: this.formData.subject,
      };
      if (this.formData.recipient === "user") {
        return notificationService.addUserNotification({
          ...data,
          user_id: this.formData.recipientId,
        });
      }

      return notificationService.addSellerNotification({
        ...data,
        seller_id: this.formData.recipientId,
      });
    },
    validate() {
      const errors = {};

      if (!this.formData.recipient?.trim()) {
        errors.recipient = [this.$t("errorMessages.required")];
      }

      if (!this.formData.recipientId) {
        errors.recipientId = [this.$t("errorMessages.required")];
      }

      if (!this.formData.subject?.trim()) {
        errors.subject = [this.$t("errorMessages.required")];
      }

      if (!this.formData.message?.trim()) {
        errors.message = [this.$t("errorMessages.required")];
      }

      if (Object.keys(errors).length) {
        this.errors.record(errors);
      }
    },
    async sendNotification() {
      this.validate();
      if (this.errors.any()) {
        return;
      }

      try {
        this.loading = true;
        const response = await this.requestNotification();
        this.close();
        this.$emit("added");

        this.$bvToast.toast(response?.data?.message?.[0]);
      } catch (error) {
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    open() {
      this.$helpers.openModal("modalSendNotification");
    },
    close() {
      this.$helpers.closeModal("modalSendNotification");
      this.resetForm();
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
</style>
