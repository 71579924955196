<template>
  <NbCard id="labels" :title="$t('documentsAndLabels')">
    <template #body>
      <div class="grid grid-cols-2 gap-4">
        <NbButton variant="secondary" @click="generateVolumeLabel()">
          {{ $t("volumesViewPage.label") }}
        </NbButton>
        <NbButton variant="secondary" @click="generateVolumeFirstMile()">
          {{ $t("volumesPage.fields.firstMileLabel") }}
        </NbButton>
        <NbButton variant="secondary" @click="generatePackingSlip()">
          {{ $t("volumesViewPage.packingSlip") }}
        </NbButton>
        <NbButton variant="secondary" @click="generateCommercialInvoice()">
          {{ $t("volumesViewPage.commercialInvoice") }}
        </NbButton>
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbCard from "@/components/cards/NbCard.vue";
import OrderLabelService from "@/services/OrderLabelService";
import PackingSlipService from "@/services/PackingSlipService";

const orderLabelService = new OrderLabelService();
const packingSlipService = new PackingSlipService();

export default {
  props: {
    volumeId: {
      type: [Number, String],
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    NbButton,
    NbCard,
  },
  methods: {
    generateCommercialInvoice() {
      orderLabelService
        .generateCommercialInvoiceLabel(this.orderId, this.volumeId)
        .then((response) => {
          if (response.data.data.download_url) {
            window.open(response.data.data.download_url);
          }
        });
    },
    generateVolumeLabel() {
      orderLabelService.generateVolumeLabel(this.volumeId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateVolumeFirstMile() {
      orderLabelService
        .generateVolumeFirstMile(this.volumeId)
        .then((response) => {
          Object.prototype.hasOwnProperty.call(response.data, "data");
          if (
            Object.prototype.hasOwnProperty.call(response.data, "data") &&
            Object.prototype.hasOwnProperty.call(
              response.data.data,
              "download_url",
            )
          ) {
            window.open(response.data.data.download_url);
          }
        });
    },
    generatePackingSlip() {
      packingSlipService.generate(this.orderId).then((response) => {
        if (response.data.data.download_url) {
          window.open(response.data.data.download_url);
        }
      });
    },
  },
};
</script>
