<template>
  <div>
    <header-title :title="title" />
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.message')"
              :val="volumeCheckpoint.message"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.attachment')"
              :val="volumeCheckpoint.attachment"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.volumeID')"
              :val="volumeCheckpoint.volume_id"
              :link="`/shipping/volumes?id=`"
              :link-id="volumeCheckpoint.volume_id"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.receivedCheckpointID')"
              :val="volumeCheckpoint.received_checkpoint_id"
              :link="`/checkpoints/received_checkpoints/`"
              :link-id="volumeCheckpoint.received_checkpoint_id"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.ConvertedNotifs')"
              :val="volumeCheckpoint.converted_notifs"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.checkpointID')"
              :val="volumeCheckpoint.checkpoint_id"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.created')"
              :val="volumeCheckpoint.created_at"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.dateIso')"
              :val="volumeCheckpoint.date_iso"
            />
          </div>
        </div>
        <br />
        <div
          v-if="volumeCheckpoint.checkpoint"
          class="card border-white rounded py-4"
        >
          <div class="d-flex justify-content-between mx-4">
            <h5 class="font-weight-light mb-4 text-uppercase">
              {{ $t("volumeCheckpointsViewPage.checkpoint") }} #{{
                volumeCheckpoint.checkpoint_id
              }}
            </h5>
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.type')"
              :val="volumeCheckpoint.checkpoint.type"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.slug')"
              :val="volumeCheckpoint.checkpoint.slug"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.name')"
              :val="volumeCheckpoint.checkpoint.name"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.description')"
              :val="volumeCheckpoint.checkpoint.description"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.trackingCode')"
              :val="volumeCheckpoint.checkpoint.tracking_code"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.titleField')"
              :val="volumeCheckpoint.checkpoint.title"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.namePT')"
              :val="volumeCheckpoint.checkpoint.name_pt"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.descriptionPT')"
              :val="volumeCheckpoint.checkpoint.description_pt"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.titlePT')"
              :val="volumeCheckpoint.checkpoint.title_pt"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.hierarchyStatus')"
              :val="volumeCheckpoint.checkpoint.hierarchy_status"
            />
          </div>
        </div>
        <div v-if="volume" class="card border-white rounded my-4 py-4 py-4">
          <div class="d-flex justify-content-between mx-4">
            <h5 class="font-weight-light mb-4 text-uppercase">
              volume #{{ volume.id }}
            </h5>
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.height')"
              :val="volume.height"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.width')"
              :val="volume.width"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.length')"
              :val="volume.length"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.weight')"
              :val="volume.weight"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.cubageFactor')"
              :val="volume.cubage_factor"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.volumeType')"
              :val="volume.volume_type"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.orderID')"
              :val="volume.order_id"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.lastMileTrackingNumber')"
              :val="volume.last_mile_tracking_number"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('volumeCheckpointsViewPage.orderReference')"
              :val="volume.current_status.tracking_code"
            />
            <field-view
              :field="$t('volumeCheckpointsViewPage.checkpoint')"
              :val="volume.order_reference"
            />
          </div>
        </div>
        <b-table
          v-if="volume"
          id="my-volume-checkpoint"
          selectable
          select-mode="single"
          :items="volume.order_items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          small
          striped
        >
          <template #cell(value)="data">
            {{ standardizeValue(data.value) }}
          </template>
        </b-table>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import FieldView from "../../../components/FieldView.vue";
import VolumeService from "../../../services/VolumeService";
import NProgress from "nprogress";
import HeaderTitle from "../../../components/HeaderTitle.vue";

const volumeService = new VolumeService();

export default {
  name: "VolumeCheckpointView",

  components: { FieldView, HeaderTitle },
  data: () => {
    return {
      volumeCheckpoint: {},
      perPage: 20,

      totalRows: 1,
      volume: null,
    };
  },
  computed: {
    title() {
      return this.$t("volumeCheckpointsViewPage.title") + this.$route.params.id;
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "name",
          label: this.$t("volumeCheckpointsViewPage.fields.name"),
          sortable: false,
        },
        {
          key: "description",
          label: this.$t("volumeCheckpointsViewPage.fields.description"),
          sortable: false,
        },
        {
          key: "sku",
          label: this.$t("volumeCheckpointsViewPage.fields.sku"),
          sortable: false,
        },
        {
          key: "value_with_tax",
          label: this.$t("volumeCheckpointsViewPage.fields.valueWithTax"),
          sortable: false,
        },
        {
          key: "value",
          label: this.$t("volumeCheckpointsViewPage.fields.value"),
          sortable: false,
        },
        {
          key: "hs_code",
          label: this.$t("volumeCheckpointsViewPage.fields.hsCode"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$t("volumeCheckpointsViewPage.fields.quantity"),
          sortable: false,
        },
        {
          key: "origin_country",
          label:
            this.$t("volumeCheckpointsViewPage.fields.originCountry") +
            "Origin Country",
          sortable: false,
        },
        {
          key: "length",
          label: this.$t("volumeCheckpointsViewPage.fields.length") + "Length",
          sortable: false,
        },
        {
          key: "width",
          label: this.$t("volumeCheckpointsViewPage.fields.width") + "Width",
          sortable: false,
        },
        {
          key: "height",
          label: this.$t("volumeCheckpointsViewPage.fields.height") + "Height",
          sortable: false,
        },
        {
          key: "weight",
          label: this.$t("volumeCheckpointsViewPage.fields.weight") + "Weight",
          sortable: false,
        },
      ];
    },
  },
  beforeMount() {
    this.reloadVolumeCheckpoint();
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    reloadVolumeCheckpoint() {
      volumeService
        .getVolumeCheckpoint(this.$route.params.id)
        .then((response) => {
          this.volumeCheckpoint = response.data.data;
          const time = this.volumeCheckpoint.created_at;
          const date = this.volumeCheckpoint.created_at;
          const updated = this.volumeCheckpoint.updated_at;
          // this.volumeCheckpoint.time = this.formateTime(time);
          this.volumeCheckpoint.created_at = this.formateDate(date, time);
          this.volumeCheckpoint.updated_at = this.formateDate(updated);
          if (this.volumeCheckpoint.date_iso) {
            const date_iso = this.volumeCheckpoint.date_iso;
            this.volumeCheckpoint.date_iso = this.formateDate(
              date_iso,
              date_iso,
            );
          }

          //TODO: A resposta do back já deveria conter o volume
          if (!this.volumeCheckpoint.volume) {
            volumeService
              .getVolume(this.volumeCheckpoint.volume_id)
              .then((response) => {
                this.volume = response.data.data;
              })
              .catch(() => {
                this.volume = null;
                NProgress.done();
                this.$bvToast.toast("Volume Not Found", {
                  title: "Error",
                  toaster: "b-toaster-top-right",
                  variant: "danger",
                  autoHideDelay: 10000,
                  appendToast: true,
                  solid: true,
                });
              });
          } else {
            this.volume = this.volumeCheckpoint.volume;
          }
        });
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
  },
};
</script>
<style></style>
