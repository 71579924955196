<template>
  <div>
    <NbModal
      :id="id"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      width="76rem"
      @close="$emit('close')"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ `${$t("receivedCheckpointsViewPage.title")} ${itemId}` }}
          </h5>
        </div>
      </template>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="checkpoint-tracking_number"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('receivedCheckpointsViewPage.trackingNumber')"
                v-model="checkpoint.tracking_number"
                disabled
              />
              <NbTextInput
                id="checkpoint-tracking_function"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('receivedCheckpointsViewPage.trackingFunction')"
                v-model="checkpoint.tracking_function"
                disabled
              />
              <NbTextInput
                id="checkpoint-status_code"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('receivedCheckpointsViewPage.statusCode')"
                v-model="checkpoint.status_code"
                disabled
              />
              <NbTextInput
                id="checkpoint-date_iso"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('receivedCheckpointsViewPage.dateIso')"
                v-model="checkpoint.date_iso"
                disabled
              />
              <NbTextInput
                id="checkpoint-created_at"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('receivedCheckpointsViewPage.date')"
                v-model="checkpoint.created_at"
                disabled
              />
              <NbTextInput
                id="checkpoint-message"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-100 my-2"
                :name="$t('receivedCheckpointsViewPage.message')"
                v-model="checkpoint.message"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ReceivedCheckpointService from "@/services/ReceivedCheckpointService";
const checkpointService = new ReceivedCheckpointService();

export default {
  components: { NbModal, NbButton, NbTextInput, NbCard, NbFooter },
  name: "ModalReceivedCheckpointsView",
  props: {
    id: {
      type: String,
      default: "ModalReceivedCheckpointsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      checkpoint: {},
      notifications: [],
      fields: [],
    };
  },
  created() {},
  computed: {
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "receiver_type",
          label: this.$t("receivedCheckpointsViewPage.fields.receiverType"),
          sortable: false,
        },
        {
          key: "nb_notification_id",
          label: this.$t("receivedCheckpointsViewPage.fields.notificationID"),
          sortable: false,
        },
        {
          key: "volume_id",
          label: this.$t("receivedCheckpointsViewPage.fields.volumeID"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("receivedCheckpointsViewPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
  },
  methods: {
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        checkpointService.getReceivedCheckpoint(newVal).then((response) => {
          this.checkpoint = response.data.data;

          let date = this.checkpoint.created_at;
          this.checkpoint.created_at = this.formateDate(date, date);

          date = this.checkpoint.date_iso;
          this.checkpoint.date_iso = this.formateDate(date, date);
        });
      }
    },
  },
};
</script>

<style scoped></style>
