<template>
  <div>
    <div class="d-md-flex justify-content-between">
      <NbPageTitle
        :title="$t('notificationsPage.title')"
        :subTitle="$t('notificationsPage.subtitle')"
      />

      <div class="btn-groups d-flex flex-column">
        <NbButton class="text-left" @click="onOpenModal">
          <NbIcon class="mr-2" icon="plus" />
          {{ $t("notificationsPage.newNotification") }}
        </NbButton>
      </div>
    </div>

    <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

    <NbTablev2
      tableOf="notifications"
      namespace="notifications"
      ref="notificationsTable"
      :clickable="true"
      :fields="fields"
      :all-fields="tableFields"
      :filter-options="filterOptions"
      @reloadFields="fields = $event"
      @total="totalNotifications = $event"
      @clickedRow="showMessage($event)"
    >
      <template #cell(title)="data">
        <div :class="data.item.is_new ? 'btn font-weight-bold' : 'btn'">
          {{
            data.item.kind == "seller_notifications"
              ? sellerNotificationTitles[data.item.title]
              : userNotificationTitles[data.item.title]
          }}
        </div>
      </template>
      <template #cell(is_new)="data">
        <NbBadge
          class="d-flex"
          :text="data.item.is_new ? $t('sent') : $t('notificationsPage.readed')"
          :type="!data.item.is_new ? 'success' : 'attention-dark'"
        />
      </template>

      <template #cell(kind)="data">
        <div :class="data.item.is_new ? 'btn font-weight-bold' : 'btn'">
          {{
            data.item.kind == "seller_notifications"
              ? $t("notificationsPage.seller")
              : $t("notificationsPage.user")
          }}
        </div>
      </template>
      <template #cell(name)="data">
        <div :class="data.item.is_new ? 'btn font-weight-bold' : 'btn'">
          <router-link
            :to="
              `/users_management/${
                data.item.kind === 'seller_notifications' ? 'sellers' : 'users'
              }/` + data.item.member_id
            "
            >{{ data.item.name }}</router-link
          >
        </div>
      </template>

      <template #cell(created_at)="data">
        <div>
          <span class="d-block body-3">
            {{ data.item.created_at | parseDate }}
          </span>
          <!-- <small class="body-2">{{ data.item.created_at | parseTime }}</small> -->
        </div>
      </template>

      <template #cell(actions)="data">
        <NbButton variant="tertiary" @click="showMessage(data.item)"
          >{{ $t("notificationsPage.seeMessage") }}></NbButton
        >
      </template>
    </NbTablev2>

    <ModalSendNotification ref="showModalSendNotification" @added="loadItems" />

    <ModalMessage
      ref="modalMessage"
      @reload="loadItems"
      :notification="selectedNotification"
    />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import ModalSendNotification from "./components/ModalSendNotification.vue";
import ModalMessage from "./components/ModalMessage.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import moment from "moment";
import notificationMixin from "./mixins/notification-mixin";
import { NotificationsTitle } from "../../../constants";

export default {
  components: {
    NbPageTitle,
    NbButton,
    NbIcon,
    NbTabs,
    NbTablev2,
    ModalSendNotification,
    ModalMessage,
    NbBadge,
  },
  mixins: [notificationMixin],
  filters: {
    parseDate(value) {
      return value;
      // return moment(value).format("DD/MM/YYYY");
    },
    parseTime(value) {
      return moment(value).format("HH[h]mm");
    },
  },
  data() {
    return {
      selectedNotification: {},
      fields: [],
      totalNotifications: -1,
      currentTable: "all",
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("notificationsPage.title"),
          found: this.totalNotifications,
          current: this.currentTable,
        },
      ];
    },
    tableFields() {
      return [
        {
          key: "title",
          label: this.$t("notificationsPage.subject"),
          sortable: false,
        },
        {
          key: "kind",
          label: this.$t("notificationsPage.recipientType"),
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("notificationsPage.fields.recipient"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("notificationsPage.sendDate"),
          sortable: false,
        },
        {
          key: "is_new",
          label: this.$t("notificationsPage.fields.status"),
          sortable: false,
        },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("actions"),
          sortable: false,
          class: "right--20 right-sticky",
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "title",
          label: this.$t("notificationsPage.titleNotification"),
          type: "select",
          options: [
            {
              value: NotificationsTitle.FINANCE,
              text: this.$t("notificationsPage.finance"),
            },
            {
              value: NotificationsTitle.INSUFFICIENT_BALANCE,
              text: this.$t("notificationsPage.insufficientBalance"),
            },
            {
              value: NotificationsTitle.TRACKING_ISSUE,
              text: this.$t("notificationsPage.trackingIssue"),
            },
            {
              value: NotificationsTitle.NEW_TICKET,
              text: this.$t("notificationsPage.newTicket"),
            },
            {
              value: NotificationsTitle.DATA_UPDATE,
              text: this.$t("notificationsPage.dataUpdate"),
            },
            {
              value: NotificationsTitle.CONTRACT_PERMISSION,
              text: this.$t("notificationsPage.contractPermission"),
            },
            {
              value: NotificationsTitle.CONTRACT_UPDATE,
              text: this.$t("notificationsPage.contractUpdate"),
            },
            {
              value: NotificationsTitle.PRICE_TABLE_UPDATE,
              text: this.$t("notificationsPage.priceTableUpdate"),
            },
            {
              value: NotificationsTitle.GENERAL_ISSUE,
              text: this.$t("notificationsPage.generalIssue"),
            },
          ],
        },
        {
          key: "name",
          label: this.$t("notificationsPage.recipient"),
          type: "select",
          options: this.listRecipients(),
        },
        {
          key: "kind",
          label: this.$t("notificationsPage.recipientType"),
          type: "select",
          options: [
            { value: "user_notifications", text: "User" },
            { value: "seller_notifications", text: "Seller" },
          ],
        },
        {
          key: "is_new",
          label: this.$t("userNotificationsPage.filterOptions.status"),
          type: "select",
          options: [
            { value: true, text: this.$t("sent") },
            {
              value: false,
              text: this.$t("userNotificationsPage.filterOptions.read"),
            },
          ],
        },
      ];
    },
  },
  methods: {
    switchTab(tab) {
      this.currentTable = tab;
    },
    showMessage(notification) {
      this.$refs.modalMessage?.open(notification);
    },
    loadItems() {
      this.$refs.notificationsTable?.getData();
    },
    listRecipients() {
      let recipients = [];
      this.$store.state.sellers.all_items.forEach((seller) => {
        recipients.push(seller);
      });
      this.$store.state.users.all_items.forEach((user) => {
        recipients.push(user);
      });
      return recipients.map((entity) => ({
        value: entity.name,
        text: Object.prototype.hasOwnProperty.call(entity, "seller_id")
          ? `(U) ${entity.name}`
          : `(S) ${entity.name}`,
      }));
    },
    onOpenModal() {
      this.$refs.showModalSendNotification?.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-groups {
  min-width: 15rem;
}
</style>
