<template>
  <div>
    <DatatableRoot
      namespace="volume_checkpoints"
      clickable
      :columns="tableColumns"
      :filters="tableFilters"
      @count="$emit('total', $event)"
      @rowClick="loadModalData($event.id)"
      :url-state="false"
    >
      <template #volume_id="{ row }">
        <router-link
          class="link-1"
          :to="`/shipping/volumes?id=` + row.volume_id"
        >
          {{ row.volume_id }}
        </router-link>
      </template>
      <template #received_checkpoint_id="{ row }">
        <div
          v-if="row.received_checkpoint_id"
          class="link-1"
          @click="loadModalReceivedCheckpointData(row.received_checkpoint_id)"
        >
          {{ row.received_checkpoint_id }}
        </div>
      </template>
      <template #checkpoint="{ row }">
        <div
          class="link-1"
          @click="loadModalNobordistCheckpointsData(row.checkpoint_id)"
        >
          {{ getCheckpointNameById(row.checkpoint_id) }}
        </div>
      </template>

      <template #date_iso="{ row }">
        {{ row.date_iso | dateTime }}
      </template>
      <template #created_at="{ row }">
        {{ row.created_at | dateTime }}
      </template>
    </DatatableRoot>

    <ModalVolumeCheckpointsView
      :itemId="currentItemId"
      @loadReceivedCheckpoint="loadModalReceivedCheckpointData($event)"
      @loadCheckpoint="loadModalNobordistCheckpointsData($event)"
    />
    <ModalNobordistCheckpointsView
      id="ModalVolNobordistCheckpointsView"
      :itemId="currentNobordistCheckpointsId"
    />
    <ModalReceivedCheckpointsView
      id="ModalVolReceivedCheckpointsView"
      :itemId="currentReceivedCheckpointsId"
    />
  </div>
</template>
<script>
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";
import ModalVolumeCheckpointsView from "@/views/checkpoints/volume_checkpoints/components/ModalVolumeCheckpointsView.vue";
import ModalReceivedCheckpointsView from "@/views/checkpoints/received_checkpoints/components/ModalReceivedCheckpointsView.vue";

import CheckpointService from "@/services/CheckpointService";
import moment from "moment";
import { mapState } from "vuex/dist/vuex.common.js";

const checkpointService = new CheckpointService();

export default {
  name: "VolumeCheckpoints",
  components: {
    DatatableRoot,
    ModalVolumeCheckpointsView,
    ModalNobordistCheckpointsView,
    ModalReceivedCheckpointsView,
  },
  props: {},
  filters: {
    dateTime(val) {
      return val && moment(val).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      checkpoints: [],
      currentItemId: "",
      currentNobordistCheckpointsId: "",
      currentReceivedCheckpointsId: "",
      checkpointsOption: [],
    };
  },
  created() {
    this.loadCheckpoints();
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    getCheckpointNameById(checkpointId) {
      return (
        this.checkpointsData.find((checkpoint) => checkpoint.id == checkpointId)
          ?.title || "-"
      );
    },
    checkQuery() {
      if (this.$route.query.volumeCheckpointId) {
        this.$emit("switchTab", "volumeCheckpoints");
        this.loadModalData(this.$route.query.volumeCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.volumeCheckpointId) {
          this.$router.push({ query: { volumeCheckpointId: id } });
        }

        this.$helpers.openModal("ModalVolumeCheckpointsView");
        return;
      }
    },
    loadModalNobordistCheckpointsData(id) {
      if (id) {
        this.currentNobordistCheckpointsId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }

        this.$helpers.openModal("ModalVolNobordistCheckpointsView");
        return;
      }
    },
    loadModalReceivedCheckpointData(id) {
      if (id) {
        this.currentReceivedCheckpointsId = id;

        if (!this.$route.query.receivedCheckpointId) {
          this.$router.push({ query: { receivedCheckpointId: id } });
        }

        this.$helpers.openModal("ModalVolReceivedCheckpointsView");
        return;
      }
    },
    loadCheckpoints() {
      checkpointService.getAllCheckpoints().then((response) => {
        this.checkpoints = response.data.data.elements;
        this.checkpointsOption = this.checkpoints.map((checkpoint) => ({
          value: checkpoint.tracking_code,
          text: `(${checkpoint.tracking_code}) ${checkpoint.title}`,
        }));
      });
    },
  },
  computed: {
    ...mapState("nobordist_checkpoints", {
      checkpointsData: (state) => state.all_items,
    }),
    tableColumns() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "volume_id",
          label: this.$t("volumeCheckpointsPage.fields.volumeID"),
        },
        {
          key: "received_checkpoint_id",
          label: this.$t("volumeCheckpointsPage.fields.receivedCheckpointID"),
        },
        {
          key: "checkpoint",
          label: this.$t("volumeCheckpointsPage.fields.checkpoint"),
        },
        {
          key: "date_iso",
          label: this.$t("volumeCheckpointsPage.fields.DateISO"),
        },
        {
          key: "message",
          label: this.$t("volumeCheckpointsPage.fields.message"),
        },
        {
          key: "created_at",
          label: this.$t("registrationDate"),
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("volumeCheckpointsPage.fields.volumeID"),
          inputs: [
            {
              key: "volume_id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumeCheckpointsPage.fields.receivedCheckpointID"),
          inputs: [
            {
              key: "received_checkpoint_id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumeCheckpointsPage.fields.checkpoint"),
          inputs: [
            {
              key: "tracking_code",
              type: "select",
              options: this.checkpointsOption,
              placeholder: this.$t("selectAnOption"),
            },
          ],
        },
        {
          label: `${this.$t("volumeCheckpointsPage.fields.DateISO")}`,
          inputs: [
            {
              key: "start_date_iso",
              type: "date",
            },
            {
              key: "end_date_iso",
              type: "date",
            },
          ],
        },
        {
          label: `${this.$t("registrationDate")}`,
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  watch: {},
};
</script>
