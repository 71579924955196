<template>
  <section class="mb-4">
    <div class="heading mb-2">
      <h2 class="heading-4">{{ $t("metrics") }}</h2>

      <FormDateFilter
        :is-form-disabled="isLoadingMetrics"
        :start-date="startDate"
        :end-date="endDate"
        @filter="setFilters"
      />
    </div>
    <div class="metrics">
      <NbCard
        id="created-orders"
        class="orders"
        :title="$t('homePage.ordersCreated')"
      >
        <template #body>
          <div class="boxes">
            <OrderMetricCard
              :loading="isLoadingFreightAverageCost"
              :description="$t('homePage.averageShippingCost')"
              :value="`$ ${metrics.freightAverageCost}`"
            />

            <OrderMetricCard
              :loading="isLoadingOrdersCountCompleted"
              :description="$t('homePage.completedOrders')"
              :value="`${metrics.ordersCompletedCount}`"
            />

            <OrderMetricCard
              :loading="isLoadingFlightClosedCount"
              :description="$t('homePage.closedFlights')"
              :value="`${metrics.flightsClosedCount}`"
            />
          </div>
        </template>
      </NbCard>

      <NbCard id="best-sellers" :title="$t('homePage.bestSellers')">
        <template #body>
          <BestSellersSkeleton class="mt-4" v-if="isLoadingSellersRanking" />
          <BestSellersTable v-else :sellers-ranking="metrics.sellersRanking" />
        </template>
      </NbCard>
    </div>
  </section>
</template>

<script>
import BestSellersTable from "./BestSellersTable.vue";
import NbCard from "@/components/cards/NbCard.vue";
import OrderMetricCard from "./OrderMetricCard.vue";
import BestSellersSkeleton from "./BestSellersSkeleton.vue";
import moment from "moment";

import MetricsService from "@/services/MetricsService";
import FormDateFilter from "./FormDateFilter.vue";
const metricsService = new MetricsService();

export default {
  components: {
    BestSellersTable,
    OrderMetricCard,
    NbCard,
    BestSellersSkeleton,
    FormDateFilter,
  },
  data() {
    return {
      startDate: moment(new Date()).subtract(30, "days").toDate(),
      endDate: new Date(),
      metrics: {
        freightAverageCost: "0.00",
        flightsClosedCount: 0,
        ordersCompletedCount: 0,
        sellersRanking: [],
      },
      isLoadingFreightAverageCost: false,
      isLoadingFlightClosedCount: false,
      isLoadingOrdersCountCompleted: false,
      isLoadingSellersRanking: false,
    };
  },
  computed: {
    isLoadingMetrics() {
      return (
        this.isLoadingFreightAverageCost ||
        this.isLoadingFlightClosedCount ||
        this.isLoadingOrdersCountCompleted ||
        this.isLoadingSellersRanking
      );
    },
    formatedRangeDate() {
      return {
        startDate: moment(this.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.endDate).format("YYYY-MM-DD"),
      };
    },
  },
  methods: {
    setFilters(filters) {
      this.startDate = filters.startDate;
      this.endDate = filters.endDate;
      this.loadMetrics();
    },
    loadMetrics() {
      this.loadFreightAverageCost();
      this.loadFlightsClosedCount();
      this.loadOrdersCompletedCount();
      this.loadBestSellersRanking();
    },
    loadFreightAverageCost() {
      this.isLoadingFreightAverageCost = true;
      metricsService
        .getFreightAverageCost(this.formatedRangeDate)
        .then((response) => {
          this.metrics.freightAverageCost = parseFloat(
            response.data.data,
          ).toFixed(2);
        })
        .finally(() => (this.isLoadingFreightAverageCost = false));
    },
    loadFlightsClosedCount() {
      this.isLoadingFlightClosedCount = true;
      metricsService
        .getFlightCountClosed(this.formatedRangeDate)
        .then((response) => {
          this.metrics.flightsClosedCount = response.data.data || 0;
        })
        .finally(() => (this.isLoadingFlightClosedCount = false));
    },
    loadOrdersCompletedCount() {
      this.isLoadingOrdersCountCompleted = true;
      metricsService
        .getOrdersCountCompleted(this.formatedRangeDate)
        .then((response) => {
          this.metrics.ordersCompletedCount = response.data.data;
        })
        .finally(() => (this.isLoadingOrdersCountCompleted = false));
    },
    loadBestSellersRanking() {
      this.isLoadingSellersRanking = true;
      metricsService
        .getSellersRanking(this.formatedRangeDate)
        .then((response) => {
          this.metrics.sellersRanking = response.data.data;
        })
        .finally(() => (this.isLoadingSellersRanking = false));
    },
  },
  created() {
    this.loadMetrics();
  },
};
</script>

<style lang="scss" scoped>
.metrics {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .orders {
      grid-column: span 2 / span 2;
    }
    .boxes {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.boxes {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
