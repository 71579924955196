<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('rejectedOrderPage.title')"
      :subTitle="$t('rejectedOrderPage.subTitle')"
    >
      <template #title> </template>
      <!-- Botoes -->
    </NbPageTitle>
    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <DatatableRoot
        namespace="orders/import/errors"
        clickable
        :columns="tableColumns"
        @count="totalRejectedOrders = $event"
        @rowClick="loadModalData($event.id)"
      >
        <template #seller_name="{ row }">
          <router-link
            :to="{
              path: `/users_management/sellers?id=${row.seller_id}`,
            }"
            class="link-1"
          >
            {{ row.seller_name }}
          </router-link>
        </template>
        <template #content="{ row }">
          {{ truncateString(row.content, 65) }}
        </template>
        <template #error_info="{ row }">
          {{ truncateString(row.error_info, 65) }}
        </template>

        <template #created_at="{ row }">
          {{ row.created_at | datetime }}
        </template>
      </DatatableRoot>
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <!-- modals -->
    <NbModal
      id="codeView"
      modalConfig="modal-dialog-centered"
      width="850px"
      :preventClose="true"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("rejectedOrderViewPage.title") }}
          {{ currentOrderRejectedView.seller_id }}
        </div>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="w-32-5 my-2">
            <label class="intut-label">{{
              $t("rejectedOrderPage.sellerName")
            }}</label>
            <div
              class="input-view link-1"
              @click="
                navigateTo(
                  `/users_management/sellers?id=${currentOrderRejectedView.seller_id}`,
                )
              "
            >
              <div class="truncate link-1">
                {{ currentOrderRejectedView.seller_name || "-" }}
              </div>
            </div>
          </div>
          <NbTextInput
            id="seller_name"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-32-5 my-2"
            :name="$t('rejectedOrderPage.fields.orderNumber')"
            :value="currentOrderRejectedView?.content?.order_number"
            disabled
          />
          <NbTextInput
            id="created_at"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-32-5 my-2"
            :name="$t('rejectedOrderPage.fields.dateImport')"
            v-model="currentOrderRejectedView.created_at"
            disabled
          />
        </div>
        <span class="body-4">
          {{ $t("rejectedOrderPage.fields.orderInfo") }}
        </span>
        <pre class="border rounded px-4 bg-gray-10" style="max-height: 450px">
          <code>
{{ currentOrderRejectedView.content }} 
          </code>
        </pre>
        <span class="body-4">
          {{ $t("rejectedOrderPage.fields.error") }}
        </span>
        <pre class="border rounded px-4 bg-gray-10" style="max-height: 450px">
          <code>
{{ currentOrderRejectedView.error_info }} 
          </code>
        </pre>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div></div>
          <NbButton
            variant="secondary"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modals -->
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";

import OrderService from "@/services/OrderService";
import moment from "moment";

const orderService = new OrderService();

export default {
  name: "ExternalLogs",
  components: {
    NbPageTitle,
    NbTabs,
    DatatableRoot,
    NbFooter,
    NbModal,
    NbTextInput,
    NbButton,
  },
  filters: {
    datetime(val) {
      return val && moment(val).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      totalRejectedOrders: -1,
      currentTable: "all",
      currentOrderRejectedView: {},
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("rejectedOrderPage.title"),
          found: this.totalRejectedOrders || 0,
          current: this.currentTable,
        },
      ];
    },
    tableColumns() {
      return [
        {
          key: "id",
          label: "Order Log ID",
        },
        {
          key: "order_number",
          label: this.$t("rejectedOrderPage.fields.orderNumber"),
        },
        {
          key: "created_at",
          label: this.$t("rejectedOrderPage.fields.dateImport"),
        },
        {
          key: "content",
          label: this.$t("rejectedOrderPage.fields.orderInfo"),
        },
        {
          key: "seller_name",
          label: this.$t("rejectedOrderPage.sellerName"),
        },
        {
          key: "error_info",
          label: this.$t("rejectedOrderPage.fields.error"),
        },
      ];
    },
  },
  mounted() {
    this.fields = this.allFields;
    this.checkQuery();
  },
  methods: {
    navigateTo(path) {
      this.$helpers.closeModal("codeView");
      this.$router.push(path);
    },
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
    checkQuery() {
      if (this.$route.query.orderRejectId) {
        //this.$emit("switchTab", "orderRejectId"); //if have more tabs
        this.loadModalData(this.$route.query.orderRejectId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        if (!this.$route.query.orderRejectId) {
          this.$router.push({ query: { orderRejectId: id } });
        }
        orderService.getOrderLog(id).then((response) => {
          this.currentOrderRejectedView = response.data.data;
          this.currentOrderRejectedView.created_at = this.formateDate(
            this.currentOrderRejectedView.created_at,
          );
        });

        this.$helpers.openModal("codeView");
        return;
      }
    },
    truncateString(str, limit) {
      let finalStr = str;
      if (typeof finalStr !== "string") {
        finalStr = JSON.stringify(str);
      }
      if (finalStr.length > limit) {
        return finalStr.substring(0, limit) + "(...)";
      }
      return finalStr;
    },
    addZero(number) {
      if (number <= 9) return "0" + number;
      else return number;
    },
    formateDate(date) {
      const data = new Date(date);
      const hours = new Date(date);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
  },
};
</script>
