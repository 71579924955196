<template>
  <div>
    <span>{{ $t("filterByPeriod") }}</span>
    <form @submit.prevent="applyFilters" class="d-flex align-items-stretch">
      <NbDatePicker v-model="form.startDate" position="left" />
      <small class="align-self-center mx-2">{{ $t("until") }}</small>
      <NbDatePicker v-model="form.endDate" class="mx-2" position="left" />
      <div class="separator mx-4 mt-2" />

      <NbButton type="submit" class="h-auto" :disabled="isFormDisabled">
        <NbIcon icon="search" size="18" />
      </NbButton>
    </form>
  </div>
</template>

<script>
import NbDatePicker from "@/components/generic/NbDatePicker.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  props: {
    isFormDisabled: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    endDate: {
      type: Date,
      default: () => new Date(),
    },
  },
  components: {
    NbDatePicker,
    NbButton,
    NbIcon,
  },
  data() {
    return {
      form: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    };
  },
  methods: {
    applyFilters() {
      this.$emit("filter", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  width: 1px;
  height: 2rem;
  background-color: var(--gray-10);
}
</style>
