<template>
  <div>
    <DatatableRoot
      namespace="received_checkpoints"
      clickable
      :columns="tableColumns"
      :filters="tableFilters"
      @count="$emit('total', $event)"
      @rowClick="loadModalData($event.id)"
      :url-state="false"
    >
      <template #date_iso="{ row }">
        {{ row.date_iso | dateTime }}
      </template>
      <template #created_at="{ row }">
        {{ row.created_at | dateTime }}
      </template>
    </DatatableRoot>

    <ModalReceivedCheckpointsView :itemId="currentItemId" />
  </div>
</template>
<script>
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import ModalReceivedCheckpointsView from "@/views/checkpoints/received_checkpoints/components/ModalReceivedCheckpointsView.vue";
import moment from "moment";

export default {
  name: "ReceivedCheckpoints",
  components: {
    DatatableRoot,
    ModalReceivedCheckpointsView,
  },
  props: {},
  filters: {
    dateTime(val) {
      return val && moment(val).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      currentItemId: "",
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.receivedCheckpointId) {
        this.$emit("switchTab", "receivedCheckpoints");
        this.loadModalData(this.$route.query.receivedCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;
        if (!this.$route.query.receivedCheckpointId) {
          this.$router.push({ query: { receivedCheckpointId: id } });
        }
        this.$helpers.openModal("ModalReceivedCheckpointsView");
        return;
      }
    },
  },
  computed: {
    tableColumns() {
      return [
        { key: "id", label: "ID" },
        {
          key: "tracking_number",
          label: this.$t("receivedCheckpointsPage.fields.trackingNumber"),
        },
        {
          key: "tracking_function",
          label: this.$t("receivedCheckpointsPage.fields.trackingFunction"),
        },
        {
          key: "status_code",
          label: this.$t("receivedCheckpointsPage.fields.statusCode"),
        },
        {
          key: "message",
          label: this.$t("receivedCheckpointsPage.fields.message"),
        },
        {
          key: "date_iso",
          label: this.$t("receivedCheckpointsPage.fields.dateIso"),
        },
        {
          key: "created_at",
          label: this.$t("receivedCheckpointsPage.fields.creationDate"),
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("receivedCheckpointsPage.fields.trackingNumber"),
          inputs: [{ key: "tracking_number" }],
        },
        {
          label: this.$t("receivedCheckpointsPage.fields.trackingFunction"),
          inputs: [{ key: "tracking_function" }],
        },
        {
          label: this.$t("receivedCheckpointsPage.fields.statusCode"),
          inputs: [{ key: "status_code" }],
        },
        {
          label: `${this.$t("receivedCheckpointsPage.fields.dateIso")}`,
          inputs: [
            {
              key: "start_date_iso",
              type: "date",
            },
            {
              key: "end_date_iso",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  watch: {},
};
</script>
